import membershipPrices from './constants/membershipPrices';

const LoyaltyActionVideoBuilderComponent = {
  name: '_LoyaltyActionVideo_',
  noWrap: true,
  inputs: [
    {
      name: 'video',
      type: 'text',
      helperText: 'Video URL or Vimeo ID',
      required: true,
    },
    {
      name: 'rule',
      type: 'reference',
      helperText: 'Select a ruleset',
      model: 'points-for-actions',
      required: true,
    },
    {
      name: 'unknownPricingFallback',
      type: 'boolean',
      helperText: `This flag determines whether the component should act as a fallback when a user's price
        point is not found in the acceptedMembershipPrices array. It is disabled for explicitly supported
        price points and enabled for handling future membership prices.`,
    },
    {
      name: 'customTargeting',
      type: 'object',
      defaultValue: {
        acceptedMembershipPrices: membershipPrices,
        membershipPrices: [],
      },
      subFields: [
        {
          name: 'membershipPrices',
          type: 'Tags',
          enum: membershipPrices,
        },
        {
          name: 'acceptedMembershipPrices',
          type: 'Tags',
          enum: membershipPrices,
          showIf: () => false,
        },
      ],
      showIf: (options: any) => !options.get('unknownPricingFallback'),
    },
  ],
} as const;

export default LoyaltyActionVideoBuilderComponent;
