const BubbleImageWithLabelBuilderComponent = {
  name: '_BubbleImageWithLabel_',
  inputs: [
    {
      name: 'container',
      type: 'string',
      defaultValue: null,
      localized: false,
    },
    {
      name: 'mobileContainer',
      type: 'string',
      defaultValue: null,
      localized: false,
    },
    {
      name: 'labelText',
      friendlyName: 'Label Text',
      helperText: 'The text label to show for the tab when scrolling',
      type: 'string',
      defaultValue: 'New tab',
    },
  ],
} as const;

export default BubbleImageWithLabelBuilderComponent;
