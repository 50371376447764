import NAV_CONTENT_COLORS from './constants/navContentColors';

type Content = Array<any>;

type DataAnalytics = {
  label: string;
  category: string;
};

type Tab = {
  columns: Array<Content>;
  dataAnalytics: DataAnalytics;
  textLabel: string;
  textUrl: string;
  tabLabelColor: string;
  isOn: boolean;
};

const defaultTab: Tab = {
  columns: [],
  textLabel: 'Default Label',
  textUrl: '/',
  tabLabelColor: '',
  dataAnalytics: {
    label: '',
    category: 'navigation',
  },
  isOn: false,
};

function createTab(textLabel: string): Tab {
  return { ...defaultTab, textLabel };
}

export const NavTabsListWithFlyoutMenuBuilderComponent = {
  name: '_NavTabsListWithFlyoutMenu_',
  canHaveChildren: true,
  inputs: [
    {
      onChange: (options: any) => {
        if (options.get('tabs') && options.get('tabs').length > 6) {
          options.set('tabs', options.get('tabs').slice(0, 6));
          alert('Maximum tabs is 6.');
        }
      },
      name: 'tabs',
      type: 'list',
      helperText: 'Add tab for each section, ex: Womens, Mens, Scrubs',
      subFields: [
        {
          name: 'columns',
          type: 'list',
          subFields: [
            {
              name: 'content',
              type: 'uiBlocks',
              defaultValue: [],
            },
          ],
          defaultValue: [],
          helperText: 'Add at least one column',
        },
        {
          name: 'textLabel',
          friendlyName: 'Text Label',
          type: 'string',
          defaultValue: 'Default Text Label',
          localized: true,
          required: true,
        },
        {
          name: 'textUrl',
          friendlyName: 'Text URL',
          helperText: 'URL to navigate to when the tab is clicked',
          type: 'string',
          defaultValue: '/',
          required: true,
        },
        {
          name: 'tabLabelColor',
          friendlyName: 'Tab Label Color',
          helperText: 'Color of the tab label and hover border',
          type: 'string',
          enum: [
            NAV_CONTENT_COLORS.DEFAULT,
            NAV_CONTENT_COLORS.SALE,
            NAV_CONTENT_COLORS.MEMBER_OFFER,
          ],
          defaultValue: NAV_CONTENT_COLORS.DEFAULT,
        },
        {
          name: 'dataAnalytics',
          friendlyName: 'Data Analytics',
          type: 'object',
          subFields: [
            {
              name: 'label',
              friendlyName: 'Analytics Label',
              helperText:
                'Label provided through to analytics services (ex: about-us)',
              type: 'string',
            },
            {
              name: 'category',
              friendlyName: 'Analytics Category',
              helperText:
                'Category provided through to analytics services (ex: navigation)',
              type: 'string',
            },
          ],
        },
        {
          name: 'isOn',
          friendlyName: 'Is On',
          helperText:
            'Turning toggle on expands component for easier management & previewing abilities',
          type: 'boolean',
          defaultValue: false,
        },
      ],
      defaultValue: [createTab('Womens'), createTab('Mens')],
    },
  ],
} as const;

export default NavTabsListWithFlyoutMenuBuilderComponent;
