import config from 'config';

import experienceIds from './constants/experienceIds';
import genders from './constants/genders';
import membershipStatuses from './constants/membershipStatuses';

const builderLocales = config.get('public.intl.builderLocales');

const FableticsTargetedSectionBuilderComponent = {
  name: '_TargetedSection_',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F682efef23ace49afac61748dd305c70a',
  canHaveChildren: true,
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Text',
        options: {
          text: 'Content',
        },
      },
    },
  ],
  noWrap: true,
  inputs: [
    {
      name: 'localeList',
      type: 'Tags',
      enum: builderLocales,
    },
    {
      name: 'membershipStatusList',
      type: 'Tags',
      enum: membershipStatuses,
    },
    {
      name: 'experienceIdList',
      type: 'Tags',
      enum: experienceIds,
    },
    {
      name: 'genderList',
      type: 'Tags',
      enum: genders,
    },
  ],
} as const;

export default FableticsTargetedSectionBuilderComponent;
