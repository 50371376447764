const StorytellingCTATextBuilderComponent = {
  name: '_CTAText_',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F81a15681c3e74df09677dfc57a615b13',
  noWrap: true,
  defaultStyles: {
    fontWeight: '700',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: '#666666',
    backgroundColor: 'transparent',
    textAlign: 'center',
    width: 'fit-content',
    cursor: 'pointer',
  },
  inputs: [
    {
      name: 'text',
      type: 'text',
      defaultValue: 'Click me!',
      localized: true,
    },
    {
      name: 'link',
      type: 'url',
      defaultValue: '',
      localized: true,
    },
    {
      name: 'openInNewTab',
      type: 'boolean',
      defaultValue: true,
    },
  ],
} as const;

export default StorytellingCTATextBuilderComponent;
