const FableticsVimeoVideo = {
  name: '_Vimeo_',
  inputs: [
    {
      name: 'videoId',
      type: 'object',
      helperText: 'Vimeo video id',
      subFields: [
        {
          name: 'desktop',
          type: 'string',
          localized: false,
        },
        {
          name: 'mobile',
          type: 'string',
          localized: false,
        },
      ],
    },
    {
      name: 'autopause',
      type: 'boolean',
      defaultValue: true,
      localized: false,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'autopip',
      type: 'string',
      defaultValue: 'None',
      localized: false,
      advanced: true,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'autoplay',
      type: 'boolean',
      defaultValue: true,
      localized: false,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed. However, our videos autoplay when they are in the viewport.',
    },
    {
      name: 'background',
      type: 'boolean',
      defaultValue: false,
      localized: false,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'byline',
      type: 'boolean',
      defaultValue: true,
      localized: false,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'color',
      type: 'string',
      defaultValue: 'None',
      localized: false,
      advanced: true,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'colors',
      type: 'string',
      defaultValue: 'None',
      localized: false,
      advanced: true,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'controls',
      type: 'boolean',
      defaultValue: true,
      localized: false,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'dnt',
      type: 'boolean',
      defaultValue: false,
      localized: false,
      advanced: true,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'height',
      type: 'string',
      defaultValue: 'None',
      localized: false,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'interactive_markers',
      type: 'boolean',
      defaultValue: true,
      localized: false,
      advanced: true,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'interactive_params',
      type: 'string',
      defaultValue: 'None',
      localized: false,
      advanced: true,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'keyboard',
      type: 'boolean',
      defaultValue: true,
      localized: false,
      advanced: true,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'loop',
      type: 'boolean',
      defaultValue: true,
      localized: false,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'maxheight',
      type: 'string',
      defaultValue: 'None',
      localized: false,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'maxwidth',
      type: 'string',
      defaultValue: 'None',
      localized: false,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'muted',
      type: 'boolean',
      defaultValue: true,
      localized: false,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'pip',
      type: 'string',
      defaultValue: 'None',
      localized: false,
      advanced: true,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'playsinline',
      type: 'boolean',
      defaultValue: true,
      localized: false,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'portrait',
      type: 'boolean',
      defaultValue: true,
      localized: false,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'quality',
      type: 'string',
      defaultValue: 'auto',
      localized: false,
      advanced: true,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'responsive',
      type: 'boolean',
      defaultValue: false,
      localized: false,
      advanced: true,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'speed',
      type: 'boolean',
      defaultValue: false,
      localized: false,
      advanced: true,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'texttrack',
      type: 'string',
      defaultValue: 'None',
      localized: false,
      advanced: true,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'title',
      type: 'boolean',
      defaultValue: true,
      localized: false,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'transcript',
      type: 'boolean',
      defaultValue: true,
      localized: false,
      advanced: true,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'transparent',
      type: 'boolean',
      defaultValue: true,
      localized: false,
      advanced: true,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
    {
      name: 'videoAspectRatio',
      type: 'object',
      localized: false,
      helperText: 'Aspect Ratio of the Video',
      subFields: [
        {
          name: 'desktop',
          type: 'number',
          localized: false,
        },
        {
          name: 'mobile',
          type: 'number',
          localized: false,
        },
      ],
    },
    {
      name: 'videoTitle',
      type: 'string',
      localized: false,
      helperText: 'The title for this embedded video',
    },
    {
      name: 'width',
      type: 'string',
      defaultValue: 'None',
      localized: false,
      helperText:
        'Vimeo embed options documentation: https://developer.vimeo.com/player/sdk/embed',
    },
  ],
} as const;

export default FableticsVimeoVideo;
