const MOBILE_APP_CHEVRON_LINK_COLORS = {
  DEFAULT: 'default',
  SALE: 'sale',
};

const MobileAppChevronLinkListBuilderComponent = {
  name: 'MobileAppChevronLinkList',
  canHaveChildren: true,
  inputs: [
    {
      name: 'title',
      friendlyName: 'Title',
      helperText: 'The title of the chevron list.',
      type: 'string',
      defaultValue: 'Categories',
      localized: true,
      required: true,
    },
    {
      name: 'listItems',
      friendlyName: 'List Items',
      type: 'list',
      subFields: [
        {
          name: 'title',
          friendlyName: 'List Item Title',
          helperText: 'The title of the list item.',
          type: 'string',
          defaultValue: '',
          localized: true,
          required: true,
        },
        {
          name: 'textColor',
          friendlyName: 'Text Color',
          helperText: 'If selected, changes text color',
          type: 'string',
          enum: [
            MOBILE_APP_CHEVRON_LINK_COLORS.DEFAULT,
            MOBILE_APP_CHEVRON_LINK_COLORS.SALE,
          ],
          defaultValue: 'default',
          localized: true,
        },
        {
          name: 'urlPath',
          friendlyName: 'Link URL',
          helperText:
            'Only include URL slug (ex: /collections/womens-new-arrivals). Note that this takes priority over content.',
          type: 'string',
          localized: true,
        },
        {
          name: 'content',
          helperText:
            'Please use this field to add a sub-menu (ex: select a Builder Symbol). When the list item is tapped, the user is sent to the next screen within the mobile app and displays this content.',
          type: 'reference',
          model: 'Symbol',
        },
        {
          name: 'dataAnalytics',
          friendlyName: 'Data Analytics',
          type: 'object',
          subFields: [
            {
              name: 'label',
              friendlyName: 'Analytics Label',
              helperText:
                'Label provided through to analytics services. Example: T2_Men_Bottoms',
              type: 'string',
              required: true,
            },
            {
              name: 'category',
              friendlyName: 'Analytics Category',
              helperText:
                'Category provided through to analytics services. Example: navigation',
              type: 'string',
              required: true,
              localized: false,
            },
          ],
        },
      ],
    },
  ],
} as const;

export {
  MobileAppChevronLinkListBuilderComponent,
  MOBILE_APP_CHEVRON_LINK_COLORS,
};
