const FableticsLoyaltyProductGridBuilderComponent = {
  name: '_LoyaltyProductGrid_',
  inputs: [
    {
      name: 'fplGlobalCodes',
      type: 'list',
      subFields: [
        {
          name: 'fplGlobalCode',
          type: 'string',
          required: true,
        },
      ],
    },
    {
      name: 'points',
      type: 'number',
      defaultValue: 500,
      helperText:
        'Enter the minimum points needed to purchase: 500, 1000, 1500, 2000.',
      required: true,
      localized: false,
    },
  ],
} as const;

export default FableticsLoyaltyProductGridBuilderComponent;
