const defaultBlock = {
  '@type': '@builder.io/sdk:Element',
  component: {
    name: '_ComparisonUnitItem_',
    options: {
      title: 'The Best Product',
      image:
        'https://cdn.builder.io/api/v1/image/assets%2F68f76aa3250e42aa805f4103ed464c85%2Fc0514d83a0814fdbb2a1e993cba11549',
      values: [{ value: { Default: '' } }, { value: { Default: '' } }],
    },
  },
};

const ComparisonUnitBuilderComponent = {
  withChildren: true,
  name: '_ComparisonUnit_',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
  canHaveChildren: true,
  defaultStyles: {
    marginTop: '0px',
    width: '100%',
  },
  inputs: [
    {
      name: 'fieldNames',
      type: 'list',
      copyOnAdd: false,
      defaultValue: [
        { fieldName: { Default: 'Compression' } },
        { fieldName: { Default: 'Fabric' } },
      ],
      subFields: [
        {
          name: 'fieldName',
          friendlyName: 'Field Name',
          defaultValue: '',
          type: 'string',
          localized: true,
        },
      ],
    },
    {
      name: 'items',
      type: 'list',
      copyOnAdd: true,
      subFields: [
        {
          name: 'content',
          type: 'uiBlocks',
          defaultValue: [defaultBlock],
        },
      ],
      defaultValue: [
        {
          content: [defaultBlock],
        },
        {
          content: [defaultBlock],
        },
      ],
    },
  ],
} as const;

export default ComparisonUnitBuilderComponent;
