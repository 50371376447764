import { defaultFontInput } from './constants/fontFamilies';

const FableticsRichTextBuilderComponent = {
  name: '_RichText_',
  image:
    'https://firebasestorage.googleapis.com/v0/b/builder-3b0a2.appspot.com/o/images%2Fbaseline-text_fields-24px%20(1).svg?alt=media&token=12177b73-0ee3-42ca-98c6-0dd003de1929',
  inputs: [
    defaultFontInput,
    {
      name: 'text',
      type: 'richText',
      defaultValue: 'Fabletics',
      localized: true,
    },
  ],
} as const;

export default FableticsRichTextBuilderComponent;
