const ICONS = {
  CLAPPERPLAY: 'Clapper-play',
};

const VideoShoppingFeatureBuilderComponent = {
  name: '_VideoShoppingFeature_',
  inputs: [
    {
      name: 'icon',
      friendlyName: 'Icon',
      helperText: "Icon for component's display on the nav",
      type: 'string',
      enum: [ICONS.CLAPPERPLAY],
      defaultValue: ICONS.CLAPPERPLAY,
      localized: false,
    },
    {
      name: 'link',
      friendlyName: 'Link',
      type: 'string',
      defaultValue: '/',
      helperText:
        'URL to direct to. Only include URL slug. Ex- /collections/womens-new-arrivals',
      localized: true,
      required: true,
    },
    {
      name: 'desktopHoverAsset',
      friendlyName: 'Desktop Hover Asset',
      type: 'string',
      defaultValue: '',
      helperText: 'Asset container label that powers creative on hover',
      localized: false,
      required: true,
    },
    {
      name: 'desktopHoverHeader',
      friendlyName: 'Desktop Hover Header',
      type: 'string',
      defaultValue: '',
      helperText: '',
      localized: false,
      required: false,
    },
    {
      name: 'desktopHoverSubcopy',
      friendlyName: 'Desktop Hover Sub-copy',
      type: 'string',
      defaultValue: '',
      helperText: '',
      localized: true,
      required: false,
    },
    {
      name: 'desktopHoverCtaCopy',
      friendlyName: 'Desktop Hover CTA Copy',
      type: 'string',
      defaultValue: '',
      helperText: '',
      localized: true,
      required: false,
    },
    {
      name: 'dataAnalytics',
      friendlyName: 'Data Analytics',
      type: 'object',
      subFields: [
        {
          name: 'label',
          friendlyName: 'Analytics Label',
          helperText:
            'Label provided through to analytics services (ex: about-us)',
          type: 'string',
        },
        {
          name: 'category',
          friendlyName: 'Analytics Category',
          helperText:
            'Category provided through to analytics services (ex: navigation)',
          type: 'string',
        },
      ],
    },
    {
      name: 'showTooltip',
      friendlyName: 'Show Tooltip',
      type: 'boolean',
      helperText:
        'Shows a closable tooltip when rendering the icon. This will only be shown once per user session until the cookie is cleared.',
      defaultValue: false,
    },
    {
      name: 'tooltip',
      friendlyName: 'Text to show in the tooltip',
      type: 'object',
      showIf: (options: any) => options.get('showTooltip'),
      subFields: [
        {
          name: 'title',
          friendlyName: 'Tooltip Title',
          helperText: 'Title to show on top of the Tooltip',
          type: 'string',
          regex: {
            // Pattern that validates the input is no longer than 55 characters
            pattern: '^.{0,55}$',
            // flags for the RegExp constructor; for example, "gi"  */
            options: 'g',
            // message to display to end-users if the regex fails
            message:
              'Tooltip Title must be 55 characters or less (recommended 25 characters).',
          },
        },
        {
          name: 'description',
          friendlyName: 'Tooltip Description',
          helperText: 'Description to show underneath the Tooltip Title',
          type: 'string',
          regex: {
            // Pattern that validates the input is no longer than 95 characters
            pattern: '^.{0,95}$',
            // flags for the RegExp constructor; for example, "gi"  */
            options: 'g',
            // message to display to end-users if the regex fails
            message:
              'Tooltip Description must be 95 characters or less (recommended 65 characters).',
          },
        },
      ],
    },
  ],
} as const;

export default { VideoShoppingFeatureBuilderComponent, ICONS };
