const FableticsQuestionBuilderComponent = {
  name: '_Question_',
  canHaveChildren: true,
  inputs: [
    {
      name: 'type',
      friendlyName: 'Question Type',
      type: 'string',
      defaultValue: 'single-select',
      localized: false,
      required: true,
      enum: ['multi-select', 'single-select'],
      helperText:
        'This refers to the question behavior (ie: single-select or multi-select)',
    },
    {
      name: 'title',
      friendlyName: 'Question Title',
      type: 'richText',
      helperText: 'The title text of the question',
      localized: true,
      required: true,
      defaultValue: 'Question',
    },
    {
      name: 'subtitle',
      friendlyName: 'Question Subtitle',
      type: 'richText',
      defaultValue: null,
      localized: true,
      helperText: 'The subtitle text of the question',
    },
    {
      name: 'id',
      friendlyName: 'Question ID',
      type: 'string',
      defaultValue: null,
      localized: false,
      required: true,
      helperText:
        'The value of the question that gets saved for tracking purposes. Make sure your ID is unique.',
      regex: {
        pattern: '^[a-z0-9_]*$',
        message:
          'Only lower case (alphanumeric) and underscore characters allowed.',
      },
    },
    {
      name: 'answers',
      type: 'list',
      subFields: [
        {
          name: 'text',
          friendlyName: 'Answer Text',
          type: 'richText',
          helperText: 'This is the text value of the answer.',
          localized: true,
          required: true,
          defaultValue: 'Answer',
        },
        {
          name: 'value',
          friendlyName: 'Answer Value',
          type: 'string',
          helperText:
            'The value of the answer that gets saved for tracking purposes. Make sure your ID is unique.',
          defaultValue: null,
          localized: false,
          required: true,
          regex: {
            pattern: '^[a-z0-9_]*$',
            message:
              'Only lower case (alphanumeric) and underscore characters allowed.',
          },
        },
        {
          name: 'type',
          friendlyName: 'Answer Type (optional)',
          type: 'string',
          defaultValue: '',
          localized: false,
          enum: ['', 'selectAll', 'selectNone'],
          helperText:
            '(Optional) The type of this answer to give it special functionality: selectAll, selectNone, etc',
        },
        {
          name: 'quizImages',
          friendlyName: 'Answer Assets',
          type: 'object',
          subFields: [
            {
              name: 'desktopImage',
              friendlyName: 'Desktop Asset',
              type: 'object',
              helperText: 'Upload asset directly to builder.io',
              subFields: [
                {
                  name: 'imagePath',
                  type: 'file',
                  allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
                  required: true,
                  defaultValue:
                    'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
                },
              ],
            },
            {
              name: 'mobileImage',
              friendlyName: 'Mobile Asset',
              type: 'object',
              helperText: 'Upload asset directly to builder.io',
              subFields: [
                {
                  name: 'imagePath',
                  type: 'file',
                  allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
                  required: true,
                  defaultValue:
                    'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
                },
              ],
            },
          ],
        },
        {
          name: 'asset',
          friendlyName: 'CMS Assets (to be deprecated soon)',
          type: 'object',
          helperText: 'Use asset containers to pull in assets',
          subFields: [
            {
              name: 'container',
              friendlyName: 'CMS Asset Container',
              type: 'string',
              defaultValue: null,
              localized: false,
            },
            {
              name: 'mobileContainer',
              friendlyName: 'CMS Asset Container - Mobile',
              type: 'string',
              defaultValue: null,
              localized: false,
            },
          ],
        },
      ],
    },
  ],
} as const;

export default FableticsQuestionBuilderComponent;
