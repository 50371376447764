import { defaultFontInput } from './constants/fontFamilies';

const FableticsLoyaltyCashRewardGridBuilderComponent = {
  name: '_LoyaltyCashRewardGrid_',
  canHaveChildren: true,
  inputs: [
    defaultFontInput,
    {
      name: 'gridItems',
      type: 'list',
      subFields: [
        {
          name: 'points',
          type: 'number',
          defaultValue: 500,
          helperText:
            'add points for each loyalty tier default: 500, 1000, 1500, 2000.',
          required: true,
          localized: false,
        },
        {
          name: 'assetContainer',
          type: 'string',
          defaultValue: 'reward_cashback_card_500',
          helperText:
            'provide each loyalty tier with different asset containers.',
          required: true,
          localized: false,
        },
        {
          name: 'promo',
          type: 'string',
          defaultValue: 'CASHREWARD_5OFF',
          helperText: 'promo code for each loyalty tier level.',
          required: true,
        },
        {
          name: 'buttonText',
          type: 'string',
          defaultValue: 'REDEEM FOR 500 points',
          helperText: 'localized the button text message.',
          required: true,
        },
        {
          name: 'assetContainerModalDesktop',
          type: 'string',
          helperText: 'adding a customize asset Container for Desktop',
          defaultValue: 'Desktop_Cash_Rewards_Popup',
          required: true,
          localized: false,
        },
        {
          name: 'assetContainerModalMobile',
          type: 'string',
          helperText: 'adding a customize asset Container for Mobile',
          defaultValue: 'Mobile_Cash_Rewards_Popup',
          required: true,
          localized: false,
        },
        {
          name: 'headerMessage',
          type: 'string',
          helperText: 'adding a customize button Text',
          defaultValue: '$5 Cash reward',
          required: true,
        },
        {
          name: 'subHeaderMessage',
          type: 'string',
          helperText: 'adding a customize button Text',
          defaultValue: '500 points',
          required: true,
        },
        {
          name: 'termsMessage',
          type: 'longText',
          helperText: 'adding long text of terms',
          defaultValue:
            '$5 Cash Reward Disclaimer: The $5 Cash Reward is valid 60 days from redemption. Minimum purchase threshold of $5 (before tax). Must be a Fabletics VIP, Gold or Elite member in the US and AUS only for discount to apply. Member must redeem the Cash Reward using Reward points in the Rewards Shop in order for it to apply at checkout. Offer valid online and in stores. Offer cannot be applied to previous purchases and cannot be redeemed for actual cash. Offer valid for one time use within the redemption period. Fabletics store credits, member credits and gift cards may be applied toward the order to which this offer is applied. Returns and exchanges of merchandise purchased are allowed but the Cash Reward amount will be forfeited. Non-transferrable. Standard shipping rates apply. Terms of offer are subject to change at any time without notice.',
          required: true,
        },
      ],
    },
  ],
};

export default FableticsLoyaltyCashRewardGridBuilderComponent;
