export const MobileAppTabSwitcherBuilderComponent = {
  name: 'MobileAppTabSwitcher',
  inputs: [
    {
      name: 'tabs',
      friendlyName: 'Tabs',
      type: 'list',
      subFields: [
        {
          name: 'title',
          friendlyName: 'Title',
          helperText: 'The title of the tab',
          type: 'string',
          defaultValue: 'Title',
          localized: true,
          required: true,
        },
        {
          name: 'isInitialSelection',
          friendlyName: 'Initial Selection',
          helperText: 'When turned on, the tab will be selected by default',
          type: 'boolean',
          defaultValue: false,
          localized: false,
          required: false,
        },
        {
          name: 'urlPath',
          friendlyName: 'URL Path',
          helperText:
            'Only include the URL slug, e.g., /collections/womens-new-arrivals',
          type: 'string',
          defaultValue: '/',
          localized: true,
          required: true,
        },
      ],
    },
  ],
} as const;
