const defaultSlide = {
  description: { Default: `“ This is the tesminonial's description. “` },
  reference: { Default: 'THIS IS THE REFERENCE' },
  image:
    'https://cdn.builder.io/api/v1/image/assets%2F64b2b4c9c9b9497d8d207d30f85b26a7%2Fc8074819d46f4a5a85bfbfaed7912346',
};

const TestimonialsBuilderComponent = {
  name: '_Testimonials_',
  defaultStyles: {
    marginTop: '0px',
  },
  inputs: [
    {
      onChange: (options: any) => {
        // Based on https://forum.builder.io/t/how-do-i-limit-the-number-of-list-items-a-user-can-add-to-a-custom-component/251
        if (options.get('slides') && options.get('slides').length > 5) {
          options.set('slides', options.get('slides').slice(0, 5));
          alert('Maximum items is 5, delete items to continue');
        }
      },
      required: true,
      name: 'slides',
      type: 'list',
      broadcast: true,
      copyOnAdd: false,
      subFields: [
        {
          name: 'description',
          type: 'richText',
          defaultValue: '',
          required: true,
          localized: true,
        },
        {
          name: 'reference',
          type: 'text',
          defaultValue: '',
          localized: true,
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          defaultValue:
            'https://cdn.builder.io/api/v1/image/assets%2F64b2b4c9c9b9497d8d207d30f85b26a7%2Fc8074819d46f4a5a85bfbfaed7912346',
        },
      ],
      defaultValue: [defaultSlide],
    },
    {
      name: 'textColor',
      type: 'color',
      defaultValue: '#333333',
    },
    {
      name: 'autoscroll',
      helperText: 'Automatically rotate to the next slide every few seconds',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'autoscrollSpeed',
      type: 'number',
      min: 1,
      max: 1_000,
      defaultValue: 5,
      helperText: 'Seconds per slide change',
      showIf: (options: any) => options.get('autoscroll'),
    },
  ],
} as const;

export default TestimonialsBuilderComponent;
