const defaultContent = {
  '@type': '@builder.io/sdk:Element',
  component: {
    name: 'Text',
    options: {
      text: 'default text',
    },
  },
};

const ContactFormBuilderComponent = {
  name: '_ContactForm_',
  inputs: [
    {
      name: 'emarsysVariables',
      friendlyName: 'Iterable Variables',
      type: 'list',
      subFields: [
        {
          name: 'name',
          localized: false,
          type: 'string',
        },
        {
          name: 'value',
          localized: false,
          type: 'string',
        },
      ],
    },
    {
      name: 'collectEmail',
      friendlyName: 'Should Collect Email',
      localized: false,
      type: 'boolean',
    },
    {
      name: 'collectSms',
      friendlyName: 'Should Collect SMS',
      localized: false,
      type: 'boolean',
    },
    {
      name: 'isScrubs',
      helperText: 'Is this form collecting scrubs prospects?',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'showGenderSelector',
      friendlyName: 'Show Gender Selector',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'signupFormTitle',
      friendlyName: 'Contact Form Title',
      type: 'string',
    },
    {
      name: 'signupFormDescription',
      friendlyName: 'Contact Form Description',
      type: 'richText',
    },
    {
      name: 'disclosures',
      type: 'list',
      helperText:
        'this is a list of texts with checkboxes, used for things like T&C and marketing opt-in. If isRequired is not selected, the text will show up without the checkbox.',
      subFields: [
        {
          name: 'isRequired',
          type: 'boolean',
        },
        {
          name: 'text',
          type: 'richText',
        },
      ],
    },
    {
      name: 'smsDisclosure',
      friendlyName: 'SMS Disclosure',
      localized: false,
      type: 'richText',
      defaultValue: 'default SMS terms',
      helperText:
        'If the collect phone number field is used, we MUST include the smsTerms checkbox for legal reasons',
    },
    {
      name: 'submitCtaText',
      friendlyName: 'Submit Button CTA Text',
      type: 'string',
    },
    {
      name: 'showConfirmationPageInEditor',
      friendlyName: 'Show Confirmation Page In Editor',
      type: 'boolean',
    },
    {
      name: 'confirmationBox',
      friendlyName: 'Confirmation Box',
      type: 'uiBlocks',
      defaultValue: [defaultContent],
    },
  ],
} as const;

export default ContactFormBuilderComponent;
