export const FableticsHeroBuilderComponent = {
  name: '_Hero_',
  inputs: [
    {
      name: 'type',
      type: 'string',
      enum: ['carousel', 'image'],
      defaultValue: 'image',
      required: true,
      localized: false,
    },
    {
      name: 'autoPlayTimeMilliseconds',
      type: 'number',
      defaultValue: 5000,
      showIf: (options: any) => options.get('type') === 'carousel',
      localized: false,
    },
    {
      name: 'showDots',
      friendlyName: 'Show dots',
      helperText: 'Show pagination dots',
      type: 'boolean',
      defaultValue: true,
      showIf: (options: any) => options.get('type') === 'carousel',
      localized: false,
    },
    {
      name: 'groupCells',
      helperText: 'If set to true, group cells that fit into the viewport',
      type: 'boolean',
      defaultValue: false,
      showIf: (options: any) => options.get('type') === 'carousel',
      localized: false,
    },
    {
      name: 'cellAlign',
      type: 'string',
      enum: ['left', 'center', 'right'],
      defaultValue: 'center',
      helperText: 'Align cells within the carousel',
      showIf: (options: any) => options.get('type') === 'carousel',
      localized: false,
    },
    {
      name: 'contain',
      helperText:
        'Contains cells to carousel element to prevent excess scroll at beginning or end. Has no effect if wrapAround: true.',
      type: 'boolean',
      defaultValue: true,
      showIf: (options: any) => options.get('type') === 'carousel',
      localized: false,
    },
    {
      name: 'wrapAround',
      helperText:
        'At the end of cells, wrap-around to the other end for infinite scrolling.',
      type: 'boolean',
      defaultValue: false,
      showIf: (options: any) => options.get('type') === 'carousel',
      localized: false,
    },
    {
      name: 'freeScroll',
      helperText:
        'Enables content to be freely scrolled and flicked without aligning cells to an end position.',
      type: 'boolean',
      defaultValue: false,
      showIf: (options: any) => options.get('type') === 'carousel',
      localized: false,
    },
    {
      name: 'resize',
      helperText:
        'Adjusts sizes and positions when window is resized. Enabled by default resize: true.',
      type: 'boolean',
      defaultValue: true,
      showIf: (options: any) => options.get('type') === 'carousel',
      localized: false,
    },
    {
      name: 'container',
      type: 'string',
      defaultValue: null,
      localized: false,
    },
    {
      name: 'mobileContainer',
      type: 'string',
      defaultValue: null,
      localized: false,
    },
  ],
} as const;

export default FableticsHeroBuilderComponent;
