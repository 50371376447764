export const MemberInfoBuilderComponent = {
  name: '_MemberInfo_',
  inputs: [
    {
      name: 'vipMemberCreditsLabel',
      friendlyName: 'VIP - Member Credits Label',
      type: 'string',
      defaultValue: 'Member Credits',
      helperText: 'Input copy for the label near member credits display',
      localized: true,
      required: true,
    },
    {
      name: 'vipRewardPointsLabel',
      friendlyName: 'VIP - Reward Points Label',
      type: 'string',
      defaultValue: 'Reward Points',
      helperText: 'Input copy for the label near reward points display',
      localized: true,
      required: true,
    },
    {
      name: 'vipLink',
      friendlyName: 'VIP - Link',
      type: 'string',
      defaultValue: '/',
      helperText: 'Only include URL slug. Ex- /collections/womens-new-arrivals',
      required: true,
    },
    {
      name: 'trialHeader',
      friendlyName: 'Trial VIP - Header',
      type: 'string',
      defaultValue: 'Ready to become a VIP?',
      helperText: 'Input header copy for the Trial VIP experience',
      localized: true,
      required: true,
    },
    {
      name: 'trialSubheader',
      friendlyName: 'Trial VIP - Subheader',
      type: 'string',
      defaultValue: 'Discounts, rewards, and exclusive products, everyday.',
      helperText: 'Input paragraph copy for the Trial VIP experience',
      localized: true,
      required: true,
    },
    {
      name: 'trialCTACopy',
      friendlyName: 'Trial VIP - CTA Copy',
      type: 'string',
      defaultValue: 'Get full membership',
      helperText: 'Input copy for the CTA in the Trial VIP experience',
      localized: true,
      required: true,
    },
    {
      name: 'trialTextLinkCopy',
      friendlyName: 'Trial VIP - Text Link Copy',
      type: 'string',
      defaultValue: 'Learn more about our VIP Membership',
      helperText: 'Input text link copy for the Trial VIP experience',
      localized: true,
      required: true,
    },
    {
      name: 'trialTextLink',
      friendlyName: 'Trial VIP - Text Link',
      type: 'string',
      defaultValue: '/',
      helperText: 'Only include URL slug. Ex- /collections/womens-new-arrivals',
      required: true,
    },
    {
      name: 'nonVipHeader',
      friendlyName: 'Non-VIP - Header',
      type: 'string',
      defaultValue: 'Join the VIP Membership',
      helperText: 'Input copy for the header in the non-VIP experience',
      localized: true,
      required: true,
    },
    {
      name: 'nonVipSubheader',
      friendlyName: 'Non-VIP - Subheader',
      type: 'string',
      defaultValue: 'Discounts, rewards, and exclusive products, everyday.',
      helperText: 'Input copy for the paragraph copy in the non-VIP experience',
      localized: true,
      required: true,
    },
    {
      name: 'nonVipCTACopy',
      friendlyName: 'Non-VIP - CTA Copy',
      type: 'string',
      defaultValue: 'Learn More',
      helperText: 'Input copy for the CTA in the non-VIP experience',
      localized: true,
      required: true,
    },
    {
      name: 'nonVipCTALink',
      friendlyName: 'Non-VIP - CTA Link',
      type: 'string',
      defaultValue: '/',
      helperText: 'Only include URL slug. Ex- /collections/womens-new-arrivals',
      required: true,
    },
    {
      name: 'dataAnalytics',
      friendlyName: 'Data Analytics',
      type: 'object',
      subFields: [
        {
          name: 'label',
          friendlyName: 'Analytics Label',
          helperText:
            'Label provided through to analytics services (ex: about-us)',
          type: 'string',
        },
        {
          name: 'category',
          friendlyName: 'Analytics Category',
          helperText:
            'Category provided through to analytics services (ex: navigation)',
          type: 'string',
        },
      ],
    },
  ],
} as const;

export default MemberInfoBuilderComponent;
