const UgcBuilderComponent = {
  name: '_Ugc_',
  inputs: [
    {
      name: 'experienceName',
      type: 'string',
      defaultValue: null,
    },
  ],
} as const;

export default UgcBuilderComponent;
