const LoyaltyRewardProgress = {
  name: '_LoyaltyRewardProgress_',
  inputs: [
    {
      name: 'title',
      defaultValue: 'YOUR REWARDS RUNWAY:',
      type: 'text',
    },
    {
      name: 'subtitle',
      defaultValue: 'COMPLETE ACTIONS, GET REWARDED',
      type: 'text',
    },
    {
      name: 'successSubtitle',
      defaultValue: 'CONGRATS! YOU COMPLETED ALL ACTIONS',
      type: 'text',
    },
    {
      type: 'list',
      name: 'actions',
      helperText:
        'The last action of the list will be the biggest one in the UI.',
      subFields: [
        {
          type: 'reference',
          helperText: 'Select a action',
          name: 'action',
          model: 'points-for-actions',
        },
        {
          name: 'displayPoints',
          type: 'number',
          required: true,
          helperText: 'The number of points is to be shown to users',
        },
      ],
      defaultValue: [],
    },
    {
      name: 'progressTrackerPoints',
      type: 'string',
      defaultValue: '1000 pts',
    },
    {
      name: 'progressTrackerOffLabel',
      type: 'string',
      friendlyName: "Progress tracker 'off' label",
      defaultValue: '$10 off',
      helperText: "The maximum length of the 'off' label is 7 characters",
      onChange: (options: any) => {
        if (options.get('progressTrackerOffLabel').length > 7) {
          options.set(
            'progressTrackerOffLabel',
            options.get('progressTrackerOffLabel').slice(0, 7)
          );
          alert(
            "the maximum length of the 'off' label is 7, shorten it to continue"
          );
        }
      },
    },
  ],
} as const;

export default LoyaltyRewardProgress;
