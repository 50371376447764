export const StickyHeaderContainerBuilderComponent = {
  name: '_StickyHeaderContainer_',
  canHaveChildren: true,
  requiresParent: {
    component: '_HeaderModelContainer_',
    message:
      "'Sticky Header Container' can only be placed inside a 'Header Model Container'",
  },
  inputs: [
    {
      name: 'backgroundColor',
      friendlyName: 'Background Color',
      helperText: 'The background color of the sticky component.',
      type: 'color',
    },
  ],
} as const;

export default StickyHeaderContainerBuilderComponent;
