const FullWidthImageAndTextBuilderComponent = {
  withChildren: true,
  name: '_FullWidthImageAndText_',
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: '_CTAButton_',
        options: {
          text: 'Learn More!',
          link: '',
          openInNewTab: true,
        },
      },
      responsiveStyles: {
        large: {
          background: '#333333',
          color: '#fff',
          padding: '15px 25px',
          'align-self': 'center',
          'max-width': '177px',
          opacity: '0.44',
          'border-radius': '4px',
          fontSize: '18px',
          lineHeight: '24px',
        },
      },
    },
  ],
  childRequirements: {
    message: 'You can only put Buttons',
    query: {
      'component.name': { $in: ['Core:Button', '_CTAButton_', '_CTAText_'] },
    },
  },
  defaultStyles: {
    marginTop: '0px',
  },
  inputs: [
    {
      name: 'showText',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'title',
      type: 'string',
      localized: true,
      defaultValue: 'The Best Product',
    },
    {
      name: 'textColor',
      type: 'color',
      defaultValue: '#333333',
    },
    {
      name: 'description',
      type: 'richText',
      localized: true,
      defaultValue:
        'We offer the best product in the world... we will happily accept returns or exchanges up to 90 days from shipment.',
    },
    {
      name: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
    },
  ],
} as const;

export default FullWidthImageAndTextBuilderComponent;
