export const MobileAppInfoGridBuilderComponent = {
  name: 'MobileAppInfoGrid',
  canHaveChildren: true,
  inputs: [
    {
      name: 'gridTitle',
      friendlyName: 'Grid Title',
      helperText: 'The title / header of the info grid.',
      type: 'string',
      defaultValue: '',
      localized: true,
      required: true,
    },
    {
      name: 'imageLinks',
      friendlyName: 'Image Links',
      type: 'list',
      subFields: [
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          required: true,
        },
        {
          name: 'linkUrl',
          friendlyName: 'Link URL',
          helperText:
            'Only include URL slug. Ex- /collections/womens-new-arrivals',
          type: 'string',
          defaultValue: '/',
          localized: true,
          required: true,
        },
        {
          name: 'title',
          friendlyName: 'Title',
          helperText: 'The title of the item.',
          type: 'string',
          defaultValue: 'Title',
          localized: true,
          required: true,
        },
        {
          name: 'description',
          friendlyName: 'Description',
          helperText: 'If provided, adds text below the title.',
          type: 'string',
          defaultValue: '',
          localized: true,
        },
        {
          name: 'dataAnalytics',
          friendlyName: 'Data Analytics',
          type: 'object',
          subFields: [
            {
              name: 'label',
              friendlyName: 'Analytics Label',
              helperText:
                'Label provided through to analytics services. Example: T2_Men_Bottoms',
              type: 'string',
              required: true,
            },
            {
              name: 'category',
              friendlyName: 'Analytics Category',
              helperText:
                'Category provided through to analytics services. Example: navigation',
              type: 'string',
              required: true,
              localized: false,
            },
          ],
        },
      ],
    },
  ],
} as const;
