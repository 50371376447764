import NAV_CONTENT_COLORS from './constants/navContentColors';

const CALLOUT_PRIORITIES = {
  DEFAULT: 'default',
  PRIORITY1: 'priority1',
  PRIORITY2: 'priority2',
};

const NavLinkBuilderComponent = {
  name: '_NavLink_',
  requiresParent: {
    message: 'NavLink must only be used in an ListColumn or TargetedSection',
    query: {
      'component.name': {
        $in: ['_ListColumn_', '_TargetedSection_'],
      },
    },
  },
  inputs: [
    {
      name: 'linkText',
      friendlyName: 'Link Text',
      helperText: 'The text displayed as the link.',
      type: 'string',
      defaultValue: 'New Arrivals',
      localized: true,
      required: true,
    },
    {
      name: 'isBold',
      friendlyName: 'Is the Link Bold?',
      helperText: 'Turning this on will make the nav item bold.',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'linkColor',
      friendlyName: 'Link Color',
      helperText: 'Color of the link text.',
      type: 'string',
      enum: [
        NAV_CONTENT_COLORS.DEFAULT,
        NAV_CONTENT_COLORS.SALE,
        NAV_CONTENT_COLORS.MEMBER_OFFER,
      ],
      defaultValue: NAV_CONTENT_COLORS.DEFAULT,
      localized: false,
    },
    {
      name: 'linkUrl',
      friendlyName: 'Link URL',
      helperText: 'Only include URL slug. Ex- /collections/womens-new-arrivals',
      type: 'string',
      defaultValue: '/',
      localized: true,
      required: true,
    },
    {
      name: 'callout',
      friendlyName: 'Callout',
      type: 'object',
      defaultValue: {
        hasCallout: false,
        text: 'New',
        backgroundColor: CALLOUT_PRIORITIES.DEFAULT,
      },
      subFields: [
        {
          name: 'hasCallout',
          friendlyName: 'Has Callout?',
          helperText:
            'If on, the link has an associated callout to display to the right of the link. This is useful for navigation links that should display a NEW callout.',
          type: 'boolean',
          localized: false,
        },
        {
          name: 'text',
          friendlyName: 'Callout Text',
          helperText:
            'Text will appear as a callout tag near the nav link. Recommend keeping character count to 6 or less characters.',
          type: 'string',
          localized: true,
          // @ts-ignore example provided by Builder to convert value to string in subfields
          showIf: `options.get('hasCallout') === true`,
        },
        {
          name: 'backgroundColor',
          friendlyName: 'Callout Background Color',
          helperText:
            'The color of the callout background surrounding the callout text.',
          type: 'string',
          enum: [
            CALLOUT_PRIORITIES.DEFAULT,
            CALLOUT_PRIORITIES.PRIORITY1,
            CALLOUT_PRIORITIES.PRIORITY2,
          ],
          localized: false,
          // @ts-ignore example provided by Builder to convert value to string in subfields
          showIf: `options.get('hasCallout') === true`,
        },
      ],
    },
    {
      name: 'associatedColor',
      friendlyName: 'Associated Color',
      type: 'object',
      defaultValue: {
        hasAssociatedColor: false,
        color: '#000000',
      },
      subFields: [
        {
          name: 'hasAssociatedColor',
          friendlyName: 'Has Associated Color?',
          helperText:
            'Turning this on will add a swatch color near the nav link. For example, if you want the customer to shop products by a specific color.',
          type: 'boolean',
          localized: false,
        },
        {
          name: 'color',
          friendlyName: 'Associated Color',
          helperText:
            'The hex value (eg. #123456) to display as the color to the left of the link.',
          type: 'color',
          // @ts-ignore example provided by Builder to convert value to string in subfields
          showIf: `options.get('hasAssociatedColor') === true`,
        },
      ],
    },
    {
      name: 'dataAnalytics',
      friendlyName: 'Data Analytics',
      type: 'object',
      subFields: [
        {
          name: 'label',
          friendlyName: 'Analytics Label',
          helperText:
            'Label provided through to analytics services. Example: T2_Men_Bottoms',
          type: 'string',
          required: true,
          localized: false,
        },
        {
          name: 'category',
          friendlyName: 'Analytics Category',
          helperText:
            'Category provided through to analytics services. Example: navigation',
          type: 'string',
          required: true,
          localized: false,
        },
      ],
    },
  ],
} as const;

export default { NavLinkBuilderComponent, CALLOUT_PRIORITIES };
