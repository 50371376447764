import CollectionBuilderComponent from './Collection';

const FILTERS = CollectionBuilderComponent.inputs.find(
  ({ name }) => name === 'filters'
) as any;

const COLLECTIONS = CollectionBuilderComponent.inputs.filter(({ name }) => {
  switch (name) {
    case 'filters':
    case 'sideNav':
      return false;
    default:
      return true;
  }
});

const GridListBuilderComponent = {
  name: '_GridList_',
  inputs: [
    FILTERS,
    {
      name: 'collections',
      type: 'list',
      defaultValue: [
        {
          brand: '',
          fontVariant: '',
          gridCTA: {
            fontVariant: '',
          },
        },
      ],
      subFields: [...COLLECTIONS],
    },
  ],
} as const;

export default GridListBuilderComponent;
