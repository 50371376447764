const CollectionBuilderComponent = {
  name: '_Collection_',
  inputs: [
    {
      name: 'fplGlobalCodes',
      type: 'list',
      subFields: [
        {
          name: 'fplGlobalCode',
          type: 'string',
          required: true,
        },
      ],
    },
    {
      name: 'productCategoryIds',
      type: 'list',
      subFields: [
        {
          name: 'productCategoryId',
          type: 'number',
          min: 1,
          max: 1_000_000,
          required: true,
        },
      ],
    },
    {
      name: 'tagIds',
      type: 'list',
      subFields: [
        {
          name: 'tagId',
          type: 'number',
          min: 1,
          max: 1_000_000,
          required: true,
        },
      ],
    },
    {
      name: 'productTypeIds',
      type: 'list',
      subFields: [
        {
          name: 'productTypeId',
          type: 'number',
          min: 1,
          max: 1_000_000,
          required: true,
        },
      ],
    },
    {
      name: 'filters',
      type: 'list',
      defaultValue: null,
      subFields: [
        {
          name: 'filter',
          type: 'string',
          required: true,
          enum: ['default_product_category_id', 'color', 'review', 'style'],
        },
      ],
    },
    {
      name: 'sort',
      type: 'object',
      defaultValue: {
        direction: 'asc',
      },
      subFields: [
        {
          name: 'hideSortOption',
          friendlyName: 'Hide Sort Option',
          type: 'boolean',
          default: false,
        },
        {
          name: 'field',
          friendlyName: 'Sort List',
          type: 'string',
        },
        {
          name: 'direction',
          friendlyName: 'Sort Direction',
          type: 'string',
          enum: ['asc', 'desc'],
          hideFromUI: true,
        },
        {
          name: 'filterLabel',
          friendlyName: 'Sort Label',
          type: 'string',
        },
      ],
    },
    {
      name: 'includeOutOfStock',
      type: 'boolean',
    },
    {
      name: 'brand',
      type: 'string',
      enum: [],
      defaultValue: '',
    },
    {
      name: 'label',
      type: 'string',
    },
    {
      name: 'gridAssetContainer',
      type: 'string',
    },
    {
      name: 'gridAssetContainerMobile',
      type: 'string',
    },
    {
      name: 'productSource',
      type: 'string',
    },
    {
      name: 'gridSortFeature',
      type: 'string',
    },
    {
      name: 'maxItemCount',
      type: 'number',
    },
    {
      name: 'isCarousel',
      type: 'boolean',
      advanced: true,
    },
    {
      name: 'sideNav',
      type: 'object',
      advanced: true,
      subFields: [
        {
          name: 'shouldShowCategoryDropdown',
          type: 'boolean',
          defaultValue: false,
        },
        {
          name: 'shouldShowCategoryHeading',
          type: 'boolean',
          defaultValue: false,
        },
        {
          name: 'categoryNavContainer',
          type: 'string',
        },
      ],
    },
    {
      name: 'gridCTA',
      type: 'object',
      advanced: true,
      defaultValue: {},
      subFields: [
        {
          name: 'desktop',
          type: 'boolean',
        },
        {
          name: 'mobile',
          type: 'boolean',
        },
        {
          name: 'fontVariant',
          type: 'string',
          enum: [],
          hideFromUI: true,
        },
        {
          name: 'label',
          type: 'string',
          localized: true,
        },
        {
          name: 'url',
          type: 'string',
          localized: true,
        },
      ],
    },
    {
      name: 'excludePreorderProducts',
      type: 'boolean',
      advanced: true,
      defaultValue: false,
    },
  ],
} as const;

export default CollectionBuilderComponent;
