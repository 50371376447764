const ShoppabbleVideoFeaturedComponent = {
  name: '_ShoppableVideoFeatured_',
  inputs: [
    { type: 'string', name: 'buttonText', defaultValue: 'Shop now' },
    { type: 'string', name: 'title' },
    { type: 'string', name: 'description' },
    {
      type: 'list',
      name: 'videos',
      subFields: [
        {
          type: 'string',
          helperText: 'URL',
          name: 'url',
        },
      ],
      defaultValue: [],
    },
  ],
} as const;

export default ShoppabbleVideoFeaturedComponent;
