const defaultHeaderBannerLeft = {
  '@type': '@builder.io/sdk:Element',
  component: {
    name: 'Text',
    options: {
      text: 'Left slot...',
    },
  },
};

const defaultHeaderBannerCenter = {
  '@type': '@builder.io/sdk:Element',
  component: {
    name: 'Text',
    options: {
      text: 'Center Banner slot...',
    },
  },
};

const defaultBodyContent = {
  '@type': '@builder.io/sdk:Element',
  component: {
    name: 'Text',
    options: {
      text: 'Insert body content here...',
    },
  },
};

export const HamburgerMenuBuilderComponent = {
  name: '_HamburgerMenu_',
  inputs: [
    {
      name: 'headerBannerLeft',
      type: 'uiBlocks',
      defaultValue: [defaultHeaderBannerLeft],
    },
    {
      name: 'headerBannerCenter',
      type: 'uiBlocks',
      defaultValue: [defaultHeaderBannerCenter],
    },
    {
      name: 'bodyContent',
      type: 'uiBlocks',
      defaultValue: [defaultBodyContent],
    },
  ],
} as const;

export default HamburgerMenuBuilderComponent;
