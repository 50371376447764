import { getBrandValue } from '~/shared/utils/brandNameHelper';

export const fontFamilies = getBrandValue({
  savage: ['Arizona', 'Plain'],
  default: [
    'GTPressura',
    'GTPressura-Extended',
    'Neue-Haas-Grotesk-Display',
    'Neue-Haas-Grotesk-Text',
    'Assistant (DEPRECATED: DO NOT USE)',
    'Playfair-Display (DEPRECATED: DO NOT USE)',
    'Trade-Gothic-Next (DEPRECATED: DO NOT USE)',
    'Trade-Gothic-Next-Condensed (DEPRECATED: DO NOT USE)',
  ],
});

export const defaultFontInput = {
  name: 'fontFamily',
  enum: fontFamilies,
  defaultValue: getBrandValue({
    savage: 'Plain',
    default: 'GTPressura',
  }),
};
