const defaultBlock = {
  '@type': '@builder.io/sdk:Element',
  component: {
    name: '_FullWidthImageWithCaption_',
    options: {
      title: 'The Best Product',
      textColor: '#333333',
      description:
        'We offer the best product in the world... we will happily accept returns or exchanges up to 90 days from shipment.',
    },
  },
};

const TwoColumnGridBuilderComponent = {
  name: '_2ColumnGrid_',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F3aad6de36eae43b59b52c85190fdef56',
  canHaveChildren: true,
  defaultStyles: {
    marginTop: '0px',
  },
  inputs: [
    {
      onChange: (options: any) => {
        if (options.get('columns') && options.get('columns').length > 2) {
          options.set('columns', options.get('columns').slice(0, 2));
          alert('Maximum columns is 2.');
        }
      },
      name: 'columns',
      type: 'list',
      broadcast: true,
      subFields: [
        {
          name: 'content',
          type: 'uiBlocks',
          defaultValue: [],
        },
      ],
      defaultValue: [
        {
          content: [defaultBlock],
        },
        {
          content: [defaultBlock],
        },
      ],
    },
  ],
} as const;

export default TwoColumnGridBuilderComponent;
