import NAV_CONTENT_COLORS from './constants/navContentColors';

const LIST_COLUMN_CONTAINERS = {
  HEADER: 'header',
  FOOTER: 'footer',
};

const ListColumnBuilderComponent = {
  name: '_ListColumn_',
  canHaveChildren: true,
  childRequirements: {
    message:
      'Child component for a ListColumn must be a NavLink, AccountNavLink, TargetedSection, or FooterLink',
    query: {
      'component.name': {
        $in: [
          '_NavLink_',
          '_AccountNavLink_',
          '_TargetedSection_',
          '_FooterLink_',
        ],
      },
    },
  },
  inputs: [
    {
      name: 'hasColumnHeader',
      friendlyName: 'Has Column Header?',
      helperText:
        'Should this list column display a column header? Strongly recommend when using for lists of navigation links for desktop devices.',
      type: 'boolean',
      defaultValue: false,
      localized: true,
    },
    {
      name: 'columnHeader',
      friendlyName: 'Column Header Text',
      helperText: 'Modify list column header text here.',
      type: 'string',
      defaultValue: 'New & Featured',
      localized: true,
      showIf: (options: any) => options.get('hasColumnHeader'),
    },
    {
      name: 'columnHeaderColor',
      friendlyName: 'Column Header Color',
      helperText: 'Select a color for the header text.',
      type: 'string',
      enum: [
        NAV_CONTENT_COLORS.DEFAULT,
        NAV_CONTENT_COLORS.SALE,
        NAV_CONTENT_COLORS.MEMBER_OFFER,
      ],
      defaultValue: NAV_CONTENT_COLORS.DEFAULT,
      localized: true,
      showIf: (options: any) => options.get('hasColumnHeader'),
    },
    {
      name: 'columnContainer',
      friendlyName: 'Column Container',
      helperText:
        'Select the container for the list column to apply specific styling.',
      type: 'string',
      enum: [LIST_COLUMN_CONTAINERS.HEADER, LIST_COLUMN_CONTAINERS.FOOTER],
      defaultValue: LIST_COLUMN_CONTAINERS.HEADER,
      localized: false,
    },
  ],
} as const;

export default { ListColumnBuilderComponent, LIST_COLUMN_CONTAINERS };
