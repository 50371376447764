const ListRowBuilderComponent = {
  name: '_ListRow_',
  canHaveChildren: true,
  inputs: [
    {
      name: 'gapBetweenListItems',
      friendlyName: 'Gap Between List Items',
      helperText:
        'Provide the amount of space between items in the list column. The numeric value provided is in units of pixels (px).',
      type: 'number',
      defaultValue: 24,
      localized: false,
    },
  ],
} as const;

export default { ListRowBuilderComponent };
