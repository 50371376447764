const GRID_FILTERS = [
  '',
  'accessory_type',
  'activity',
  'bag_style',
  'bottom_style',
  'bra_closure_type',
  'bra_cup_type',
  'bra_style',
  'bra_support_level',
  'category',
  'collection',
  'color',
  'compression',
  'default_product_category_id',
  'discounts',
  'dg_men_bottoms',
  'dg_men_pants',
  'dg_men_polo_shirts',
  'dg_men_short_sleeve_tees',
  'dg_men_shorts',
  'dg_men_shorts_lined',
  'dg_men_shorts_unlined',
  'dg_men_sweatshirts',
  'dg_men_tank_tops',
  'dg_men_tops',
  'dg_men_underwear',
  'dg_women_bras_impact',
  'dg_women_bottoms',
  'dg_women_leggings',
  'dg_women_scrubs_bottoms',
  'dg_women_scrubs_tops',
  'dg_women_shorts',
  'dg_women_sports_bras',
  'fabric',
  'feature',
  'gender',
  'inseam',
  'jacket_style',
  'legging_length',
  'lining',
  'mens_bottom_fit',
  'mens_category',
  'mens_jacket_style',
  'mens_sweatshirt_style',
  'offers',
  'rise',
  'review',
  'scrubs_bottom_style',
  'scrubs_fit',
  'scrubs_jacket_vest',
  'scrubs_top_style',
  'shapewear',
  'size_bottom',
  'size_bra',
  'size_kid',
  'size_shoes',
  'size_top',
  'style',
  'target_area',
  'top_style',
];

export default GRID_FILTERS;
