const FooterLinkBuilderComponent = {
  name: '_FooterLink_',
  requiresParent: {
    message:
      'FooterLink must only be used in an ListColumn, ListRow, or TargetedSection',
    query: {
      'component.name': {
        $in: ['_ListColumn_', '_ListRow_', '_TargetedSection_'],
      },
    },
  },
  inputs: [
    {
      name: 'linkText',
      friendlyName: 'Link Text',
      helperText: 'The text displayed as the link.',
      type: 'string',
      defaultValue: 'New Arrivals',
      localized: true,
      required: true,
    },
    {
      name: 'linkUrl',
      friendlyName: 'Link URL',
      helperText: 'Only include URL slug. Ex- /collections/womens-new-arrivals',
      type: 'string',
      defaultValue: '/',
      localized: true,
    },
    {
      name: 'dataAnalytics',
      friendlyName: 'Data Analytics',
      type: 'object',
      subFields: [
        {
          name: 'label',
          friendlyName: 'Analytics Label',
          helperText:
            'Label provided through to analytics services. Example: T2_Men_Bottoms',
          type: 'string',
          required: true,
          localized: false,
        },
        {
          name: 'category',
          friendlyName: 'Analytics Category',
          helperText:
            'Category provided through to analytics services. Example: navigation',
          type: 'string',
          required: true,
          localized: false,
        },
      ],
    },
    {
      name: 'isBold',
      friendlyName: 'Is the Link Bold?',
      helperText: 'Turning this on will make the nav item bold.',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'isOneTrust',
      friendlyName: 'Does the Link open One Trust Options?',
      helperText: 'Turning this on will make the nav item bold.',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
    },
    {
      name: 'isAccessibilityOption',
      friendlyName: 'Does the Link open Accessibility Options?',
      helperText: 'Turning this on will make the nav item bold.',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
    },
    {
      name: 'isFaqLink',
      friendlyName: 'Does the link open help.fabletics.com?',
      helperText:
        'Turning this on will append additional query parameters based on user configurations (i.e. displaying the correct membership price).',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
    },
  ],
} as const;

export default { FooterLinkBuilderComponent };
