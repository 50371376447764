const FiftyFiftyVideoBuilderComponent = {
  withChildren: true,
  name: '_FiftyFiftyVideo_',
  image:
    'https://firebasestorage.googleapis.com/v0/b/builder-3b0a2.appspot.com/o/images%2Fbaseline-videocam-24px%20(1).svg?alt=media&token=49a84e4a-b20e-4977-a650-047f986874bb',
  defaultStyles: {
    marginTop: '0px',
  },
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Text',
        options: {
          text: 'subtitle',
        },
      },
      responsiveStyles: {
        large: {
          fontSize: '16px',
          fontWweight: '700',
          textAlign: 'center',
          textTransform: 'uppercase',
          color: '#999999',
          marginTop: '10px',
          lineHeight: '23px',
        },
      },
    },
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Text',
        options: {
          text: 'Example Title',
        },
      },
      responsiveStyles: {
        large: {
          fontSize: '22px',
          fontWeight: '700',
          marginBottom: '10px',
          lineHeight: '23px',
        },
      },
    },
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Text',
        options: {
          text: 'This is the description of a Fifty Fifty component...try it out!',
          richText: true,
        },
      },
      responsiveStyles: {
        large: {
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '26px',
        },
      },
    },
  ],
  childRequirements: {
    message: 'Only text and CTA allowed',
    query: {
      'component.name': {
        $in: ['Text', '_CTAButton_', '_CTAText_'],
      },
    },
  },
  inputs: [
    {
      name: 'videoPosition',
      enum: ['left', 'right'],
      defaultValue: 'left',
    },
    {
      name: 'backgroundColor',
      type: 'color',
      defaultValue: '#ffffff',
    },
    // following properties based on node_modules/@builder.io/react/src/blocks/Video.tsx
    {
      name: 'video',
      type: 'file',
      allowedFileTypes: ['mp4'],
      bubble: true,
      defaultValue:
        'https://firebasestorage.googleapis.com/v0/b/builder-3b0a2.appspot.com/o/assets%2FKQlEmWDxA0coC3PK6UvkrjwkIGI2%2F28cb070609f546cdbe5efa20e931aa4b?alt=media&token=912e9551-7a7c-4dfb-86b6-3da1537d1a7f',
    },
    {
      name: 'posterImage',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png'],
      helperText: 'Image to show before the video plays',
    },
    {
      name: 'autoPlay',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'controls',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'muted',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'loop',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'playsInline',
      type: 'boolean',
      helperText:
        'Setting this to false makes it so the video goes to fullscreen on some mobile devices.',
      defaultValue: true,
      advanced: true,
    },
    {
      name: 'fit',
      type: 'text',
      defaultValue: 'cover',
      enum: ['contain', 'cover', 'fill', 'auto'],
      advanced: true,
    },
    {
      name: 'position',
      type: 'text',
      defaultValue: 'center',
      enum: [
        'center',
        'top',
        'left',
        'right',
        'bottom',
        'top left',
        'top right',
        'bottom left',
        'bottom right',
      ],
      advanced: true,
    },
    {
      name: 'height',
      friendlyName: 'Height (px.)',
      helperText:
        'Leave as 0 or undefined to ignore this value (will use 100%).',
      type: 'number',
      min: 0,
      max: 4_000,
      advanced: true,
    },
    {
      name: 'width',
      friendlyName: 'Width (px.)',
      helperText:
        'Leave as 0 or undefined to ignore this value (will use 100%).',
      type: 'number',
      min: 0,
      max: 4_000,
      advanced: true,
    },
    {
      name: 'lazyLoad',
      type: 'boolean',
      helperText:
        'Load this video "lazily" - as in only when a user scrolls near the video. Recommended for optimized performance and bandwidth consumption.',
      defaultValue: true,
      advanced: true,
    },
  ],
} as const;

export default FiftyFiftyVideoBuilderComponent;
