export const MobileAppSearchBarBuilderComponent = {
  name: 'MobileAppSearchBar',
  inputs: [
    {
      name: 'placeholderText',
      friendlyName: 'Placeholder Text',
      helperText: 'The text to use for the placeholder',
      type: 'string',
      defaultValue: 'Search',
      localized: true,
      required: true,
    },
    {
      name: 'cancelText',
      friendlyName: 'Cancel Text',
      helperText: 'The text to use for the cancel button',
      type: 'string',
      defaultValue: 'Cancel',
      localized: true,
      required: true,
    },
    {
      name: 'hasWishlistButton',
      friendlyName: 'Toggle Wishlist Button',
      helperText:
        'Toggle whether the wishlist button appears next to the search bar',
      type: 'boolean',
      defaultValue: false,
      localized: true,
    },
  ],
} as const;
