const TYPES = {
  CUSTOM: 'custom',
  STORES: 'stores',
};

const ICONS = {
  NONE: 'none',
  QUESTION: 'question',
  LOGOUT: 'logout',
  LOGIN: 'login',
};

export const TertiaryNavItemBuilderComponent = {
  name: '_TertiaryNavItem_',
  inputs: [
    {
      name: 'type',
      friendlyName: 'Type',
      helperText: 'Type of nav item, custom or a built-in type',
      type: 'string',
      enum: [TYPES.CUSTOM, TYPES.STORES],
      defaultValue: TYPES.CUSTOM,
      localized: false,
      required: true,
    },
    {
      name: 'linkIcon',
      friendlyName: 'Link Icon',
      helperText: 'Optional Icon to display in front of link',
      type: 'string',
      enum: [ICONS.NONE, ICONS.QUESTION, ICONS.LOGOUT, ICONS.LOGIN],
      defaultValue: ICONS.NONE,
      localized: false,
      showIf: (options: any) => {
        return options.get('type') === 'custom';
      },
    },
    {
      name: 'linkText',
      friendlyName: 'Link Text',
      helperText: 'The text displayed as the link.',
      type: 'string',
      defaultValue: 'Help & Contact',
      localized: true,
      required: true,
      showIf: (options: any) => {
        return options.get('type') === 'custom';
      },
    },
    {
      name: 'linkUrl',
      friendlyName: 'Link URL',
      helperText: 'The destination URL for a list item that is clicked on.',
      type: 'string',
      defaultValue: '/',
      required: true,
      localized: true,
    },
    {
      name: 'dataAnalytics',
      friendlyName: 'Data Analytics',
      type: 'object',
      subFields: [
        {
          name: 'label',
          friendlyName: 'Analytics Label',
          helperText:
            'Label provided through to analytics services. Example: T2_Men_Bottoms',
          type: 'string',
          localized: false,
          required: true,
        },
        {
          name: 'category',
          friendlyName: 'Analytics Category',
          helperText:
            'Category provided through to analytics services. Example: navigation',
          type: 'string',
          localized: false,
          required: true,
        },
      ],
    },
    {
      name: 'isFaqLink',
      friendlyName: 'Does the link open help.fabletics.com?',
      helperText:
        'Turning this on will append additional query parameters based on user configurations (i.e. displaying the correct membership price).',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
    },
  ],
} as const;

export default { TertiaryNavItemBuilderComponent, ICONS, TYPES };
