const FiftyFiftyImageBuilderComponent = {
  withChildren: true,
  name: '_FiftyFiftyImage_',
  image:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgZmlsbD0iIzAwMDAwMCIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0yMDgsNTZWMjAwYTgsOCwwLDAsMS04LDhIMTI4VjQ4aDcyQTgsOCwwLDAsMSwyMDgsNTZaIiBvcGFjaXR5PSIwLjIiPjwvcGF0aD48cGF0aCBkPSJNMjAwLDQwSDU2QTE2LDE2LDAsMCwwLDQwLDU2VjIwMGExNiwxNiwwLDAsMCwxNiwxNkgyMDBhMTYsMTYsMCwwLDAsMTYtMTZWNTZBMTYsMTYsMCwwLDAsMjAwLDQwWk01Niw1Nmg2NFYyMDBINTZaTTIwMCwyMDBIMTM2VjU2aDY0VjIwMFoiPjwvcGF0aD48L3N2Zz4=',
  defaultStyles: {
    marginTop: '0px',
  },
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Text',
        options: {
          text: 'subtitle',
        },
      },
      responsiveStyles: {
        large: {
          fontSize: '16px',
          fontWweight: '700',
          textAlign: 'center',
          textTransform: 'uppercase',
          color: '#999999',
          marginTop: '10px',
          lineHeight: '23px',
        },
      },
    },
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Text',
        options: {
          text: 'Example Title',
        },
      },
      responsiveStyles: {
        large: {
          fontSize: '22px',
          fontWeight: '700',
          marginBottom: '10px',
          lineHeight: '23px',
        },
      },
    },
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Text',
        options: {
          text: 'This is the description of a Fifty Fifty component...try it out!',
          richText: true,
        },
      },
      responsiveStyles: {
        large: {
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '26px',
        },
      },
    },
  ],
  childRequirements: {
    message: 'Only text and CTA allowed',
    query: {
      'component.name': {
        $in: ['Text', '_CTAButton_', '_CTAText_'],
      },
    },
  },
  inputs: [
    {
      name: 'imagePosition',
      enum: ['left', 'right'],
      defaultValue: 'left',
    },
    {
      name: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
    },
    {
      name: 'backgroundColor',
      type: 'color',
      defaultValue: '#ffffff',
    },
  ],
} as const;

export default FiftyFiftyImageBuilderComponent;
