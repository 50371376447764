const AccountNavLinkBuilderComponent = {
  name: '_AccountNavLink_',
  requiresParent: {
    component: '_ListColumn_',
    message: 'AccountNavLink must only be used in a ListColumn',
  },
  inputs: [
    {
      name: 'linkText',
      friendlyName: 'Link Text',
      helperText: 'The text displayed as the link.',
      type: 'string',
      defaultValue: 'New Arrivals',
      localized: true,
      required: true,
    },
    {
      name: 'linkUrl',
      friendlyName: 'Link URL',
      helperText:
        'Only include URL slug. Example: /collections/womens-new-arrivals',
      type: 'string',
      defaultValue: '/',
      localized: true,
      required: true,
    },
    {
      name: 'dataAnalytics',
      friendlyName: 'Data Analytics',
      type: 'object',
      subFields: [
        {
          name: 'label',
          friendlyName: 'Analytics Label',
          helperText:
            'Label provided through to analytics services. Example: T2_Men_Bottoms',
          type: 'string',
          required: true,
          localized: false,
        },
        {
          name: 'category',
          friendlyName: 'Analytics Category',
          helperText:
            'Category provided through to analytics services. Example: navigation',
          type: 'string',
          required: true,
          localized: false,
        },
      ],
    },
  ],
} as const;

export default AccountNavLinkBuilderComponent;
