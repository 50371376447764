const StorytellingCTAButtonBuilderComponent = {
  name: '_CTAButton_',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F81a15681c3e74df09677dfc57a615b13',
  noWrap: true,
  static: true,
  defaultStyles: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingLeft: '25px',
    paddingRight: '25px',
    color: '#ffffff',
    backgroundColor: '#000000',
    borderRadius: '4px',
    textAlign: 'center',
    width: 'fit-content',
    cursor: 'pointer',
  },
  inputs: [
    {
      name: 'text',
      type: 'text',
      defaultValue: 'Click me!',
      localized: true,
    },
    {
      name: 'link',
      type: 'url',
      defaultValue: '',
      localized: true,
    },
    {
      name: 'openInNewTab',
      type: 'boolean',
      defaultValue: true,
    },
  ],
} as const;

export default StorytellingCTAButtonBuilderComponent;
