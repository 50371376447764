const FableticsAssetBuilderComponent = {
  name: '_Asset_',
  inputs: [
    {
      name: 'type',
      friendlyName: 'Asset Type',
      type: 'string',
      enum: ['carousel', 'image'],
      defaultValue: 'image',
      required: true,
      localized: false,
    },
    {
      name: 'autoPlay',
      type: 'boolean',
      defaultValue: true,
      showIf: (options: any) => options.get('type') === 'carousel',
      localized: false,
    },
    {
      name: 'container',
      friendlyName: 'CMS Asset Container',
      type: 'string',
      defaultValue: null,
      localized: false,
    },
    {
      name: 'mobileContainer',
      friendlyName: 'CMS Asset Container - Mobile',
      type: 'string',
      defaultValue: null,
      localized: false,
    },
  ],
} as const;

export default FableticsAssetBuilderComponent;
