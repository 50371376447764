const defaultContent = {
  '@type': '@builder.io/sdk:Element',
  component: {
    name: '_Text_',
    type: 'string',
    options: {
      text: 'Sample Overlayed Content',
    },
  },
  responsiveStyles: {
    large: {
      fontWeight: '600',
      fontSize: '42px',
    },
  },
};

const boxComponent = {
  '@type': '@builder.io/sdk:Element',
  '@version': 2,
  name: 'Box',
  children: [defaultContent],
  responsiveStyles: {
    large: {
      marginTop: 'auto',
      marginBottom: 'auto',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
};

const AssetOverlayBuilderComponent = {
  name: '_AssetOverlay_',
  inputs: [
    {
      name: 'container',
      friendlyName: 'CMS Asset Container',
      type: 'string',
      defaultValue: null,
      required: true,
      helperText:
        'Entering a container is required to use this component. If you do not provide an asset for the mobile container, this container will be used.',
    },
    {
      name: 'mobileContainer',
      friendlyName: 'CMS Asset Container - Mobile',
      type: 'string',
      defaultValue: null,
    },
    {
      name: 'overlay',
      type: 'uiBlocks',
      defaultValue: [boxComponent],
      localized: false,
    },
  ],
  defaultStyles: {
    width: '100%',
    height: '100%',
  },
} as const;

export default AssetOverlayBuilderComponent;
