const ModalBuilderComponent = {
  name: '_Modal_',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F682efef23ace49afac61748dd305c70a',
  canHaveChildren: true,
  noWrap: true,
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Modal',
      localized: true,
      required: true,
    },
    {
      name: 'isOpen',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'showCloseButton',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'transitionProps',
      type: 'javascript',
      defaultValue: '',
      advanced: true,
    },
    {
      name: 'modalTrigger',
      type: 'uiBlocks',
      defaultValue: [],
    },
    {
      name: 'content',
      type: 'uiBlocks',
      defaultValue: [],
    },
  ],
} as const;

export default ModalBuilderComponent;
