const FableticsCtaBuilderComponent = {
  name: '_Cta_',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F81a15681c3e74df09677dfc57a615b13',
  noWrap: true,
  inputs: [
    {
      name: 'variant',
      type: 'string',
      enum: ['primary', 'secondary', 'other', 'text_link'],
      defaultValue: 'primary',
      localized: false,
    },
    {
      name: 'text',
      type: 'string',
      defaultValue: 'Click me!',
      localized: true,
    },
    {
      name: 'link',
      type: 'string',
      defaultValue: '/',
      localized: true,
    },
    {
      name: 'mobileAppScreen',
      friendlyName: 'Mobile App Screen',
      helperText:
        'Screen that the user is routed to inside the mobile app when tapping on this CTA. Takes priority over link.',
      type: 'object',
      localized: false,
      advanced: true,
      subFields: [
        {
          name: 'screen',
          friendlyName: 'Navigation Screen',
          helperText:
            'See here for all available routes: https://confluence.techstyle.net/x/gbpZEw',
          type: 'string',
          localized: false,
        },
        {
          name: 'params',
          friendlyName: 'Navigation Parameters',
          helperText:
            'This field is optional. Please consult with the mobile app team for assistance.',
          type: 'list',
          subFields: [
            {
              name: 'name',
              localized: false,
              type: 'string',
            },
            {
              name: 'value',
              localized: false,
              type: 'string',
            },
          ],
          hideFromUI: false,
        },
      ],
    },
  ],
} as const;

export default FableticsCtaBuilderComponent;
