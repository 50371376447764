const LoyaltyActionCtaBuilderComponent = {
  name: '_LoyaltyActionCta_',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F81a15681c3e74df09677dfc57a615b13',
  noWrap: true,
  inputs: [
    {
      name: 'rule',
      type: 'reference',
      helperText: 'Select a ruleset',
      model: 'points-for-actions',
      required: true,
    },
    {
      name: 'trigger',
      friendlyName:
        'Should this CTA trigger a redirect, modal popup, or video?',
      type: 'string',
      enum: ['redirect', 'modal', 'video'],
      defaultValue: 'redirect',
      required: true,
    },
    {
      name: 'text',
      friendlyName: 'Button Text (Optional)',
      type: 'string',
      defaultValue: 'Click me!',
      localized: true,
    },
    {
      name: 'icon',
      friendlyName: 'Button Icon (Optional)',
      type: 'file',
    },
    {
      name: 'targetUrl',
      type: 'string',
      defaultValue: '',
      localized: true,
      showIf: (options: any) => options.get('trigger') === 'redirect',
    },
    {
      name: 'mobileTargetUrl',
      type: 'string',
      defaultValue: '',
      localized: true,
      showIf: (options: any) => options.get('trigger') === 'redirect',
    },
  ],
} as const;

export default LoyaltyActionCtaBuilderComponent;
