const defaultLabel = {
  '@type': '@builder.io/sdk:Element',
  component: {
    name: '_BubbleImageWithLabel_',
    options: {
      labelText: 'Bubble Image Test Label',
    },
  },
};

const defaultContent = {
  '@type': '@builder.io/sdk:Element',
  component: {
    name: 'Text',
    options: {
      text: 'Content',
    },
  },
};

const FableticsTabsBuilderComponent = {
  name: '_Tabs_',
  canHaveChildren: true,
  inputs: [
    {
      name: 'tabs',
      type: 'list',
      subFields: [
        {
          name: 'tabId',
          type: 'string',
          defaultValue: 'offer1',
          localized: false,
          required: true,
        },
        {
          name: 'label',
          type: 'uiBlocks',
          defaultValue: [defaultLabel],
          localized: false,
        },
        {
          name: 'content',
          type: 'uiBlocks',
          defaultValue: [defaultContent],
          localized: false,
        },
        {
          name: 'scrolledLabel',
          type: 'string',
          defaultValue: 'Default Scrolling Label',
        },
      ],
    },
    {
      name: 'hideScrollingLabels',
      type: 'boolean',
      defaultValue: false,
      localized: false,
      helperText:
        'Set to true to hide all the scrolling labels. If set to false, you must set a scrolling label text for each tab or the default will display.',
    },
    {
      name: 'includePostregCountdownTimer',
      type: 'boolean',
      defaultValue: false,
      helperText: 'Include a 1 hour postreg timer.',
    },
    {
      name: 'postregTimerText',
      type: 'string',
      defaultValue: 'Offer Expires in ',
      helperText:
        'The text that precedes the countdown digits if a postreg timer is present.',
    },
  ],
} as const;

export default FableticsTabsBuilderComponent;
