import { getBrandValue } from '~/shared/utils/brandNameHelper';

const TYPES = getBrandValue({
  savage: {
    DEFAULT_MARK_LOGO_SXF: 'Default Mark Logo SXF',
    DEFAULT_FULL_LOGO_SXF: 'Default Full Logo SXF',
    ASSET_CONTAINER: 'Asset Container',
  },
  default: {
    DEFAULT_MARK_LOGO_FL: 'Default Mark Logo FL',
    DEFAULT_FULL_LOGO_FL: 'Default Full Logo FL',
    DEFAULT_FULL_LOGO_YT: 'Default Full Logo YT',
    ASSET_CONTAINER: 'Asset Container',
  },
});

export const LogoBuilderComponent = {
  name: '_Logo_',
  inputs: [
    {
      name: 'type',
      friendlyName: 'Type',
      helperText:
        'Type of logo. Use "Asset Container" for custom uploads that are not found in the dropdown list. Prefer to use the dropdown list when possible for highest resolution or request an engineer to add support for a new svg version.',
      type: 'string',
      enum: Object.values(TYPES),
      defaultValue: getBrandValue({
        savage: TYPES.DEFAULT_MARK_LOGO_SXF,
        default: TYPES.DEFAULT_MARK_LOGO_FL,
      }),
      localized: false,
      required: true,
    },
    {
      name: 'assetContainer',
      friendlyName: 'Asset Container',
      type: 'string',
      helperText:
        'Asset container label, recommend uploading SVG file to the container.',
      defaultValue: 'fabletics_builder_nav_logo_desktop',
      showIf: (options: any) => {
        return options.get('type') === 'Asset Container';
      },
    },
  ],
} as const;

export default { LogoBuilderComponent, TYPES };
