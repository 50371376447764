const PointHistoryBuilderComponent = {
  name: '_PointHistory_',
  inputs: [
    {
      name: 'itemsPerPage',
      type: 'number',
      min: 1,
      max: 5_000,
      defaultValue: 4,
      required: true,
    },
  ],
} as const;

export default PointHistoryBuilderComponent;
