const FullWidthImageWithCaptionBuilderComponent = {
  name: '_FullWidthImageWithCaption_',
  defaultStyles: {
    marginTop: '0px',
  },
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'The Best Product',
      localized: true,
    },
    {
      name: 'description',
      type: 'richText',
      defaultValue:
        'We offer the best product in the world... we will happily accept returns or exchanges up to 90 days from shipment.',
      localized: true,
    },
    {
      name: 'textColor',
      friendlyName: 'Text Color',
      type: 'color',
      defaultValue: '#333333',
    },
    {
      name: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg', 'webp', 'avif'],
    },
  ],
} as const;

export default FullWidthImageWithCaptionBuilderComponent;
