export const MobileAppImageLinkGridBuilderComponent = {
  name: 'MobileAppImageLinkGrid',
  inputs: [
    {
      name: 'gridTitle',
      friendlyName: 'Grid Title',
      helperText: 'The title of the grid.',
      type: 'string',
      defaultValue: 'Grid Title',
      localized: true,
      required: true,
    },
    {
      name: 'maxRowCount',
      friendlyName: 'Max Row Count',
      helperText:
        'Maximum number of rows shown when component is not expanded.',
      type: 'number',
      defaultValue: 3,
      required: true,
    },
    {
      name: 'expandGridCtaText',
      friendlyName: 'Expand CTA text',
      helperText:
        'Text for the expand CTA if total row count is above maxRowCount',
      type: 'string',
      defaultValue: 'Show All',
      localized: true,
      required: true,
    },
    {
      name: 'minimizeGridCtaText',
      friendlyName: 'Minimize CTA text',
      helperText: 'Text for the minimize CTA if component is expanded',
      type: 'string',
      defaultValue: 'Show Less',
      localized: true,
      required: true,
    },
    {
      name: 'imageLinks',
      friendlyName: 'Image Links',
      type: 'list',
      subFields: [
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          required: true,
        },
        {
          name: 'title',
          friendlyName: 'Title',
          helperText: 'The title of the item.',
          type: 'string',
          defaultValue: 'Title',
          localized: true,
          required: true,
        },
        {
          name: 'linkUrl',
          friendlyName: 'Link URL',
          helperText:
            'Only include URL slug. Ex- /collections/womens-new-arrivals',
          type: 'string',
          defaultValue: '/',
          localized: true,
          required: true,
        },
        {
          name: 'promoText',
          friendlyName: 'Promo Text',
          helperText: 'If provided, adds text above the title.',
          type: 'string',
          defaultValue: '',
          localized: true,
        },
        {
          name: 'callout',
          friendlyName: 'Callout',
          helperText: 'If provided, adds a callout tag to the image.',
          type: 'string',
          defaultValue: '',
          localized: true,
        },
        {
          name: 'dataAnalytics',
          friendlyName: 'Data Analytics',
          type: 'object',
          subFields: [
            {
              name: 'label',
              friendlyName: 'Analytics Label',
              helperText:
                'Label provided through to analytics services. Example: T2_Men_Bottoms',
              type: 'string',
              required: true,
            },
            {
              name: 'category',
              friendlyName: 'Analytics Category',
              helperText:
                'Category provided through to analytics services. Example: navigation',
              type: 'string',
              required: true,
              localized: false,
            },
          ],
        },
      ],
    },
  ],
} as const;
