const CarouselBuilderComponent = {
  name: '_Carousel_',
  canHaveChildren: true,
  defaultStyles: {
    minHeight: '175px', // for slides with small content
    marginTop: '0px',
  },
  inputs: [
    {
      name: 'slides',
      type: 'list',
      broadcast: true,
      subFields: [
        {
          name: 'content',
          type: 'uiBlocks',
          defaultValue: [],
        },
      ],
      defaultValue: [
        {
          content: [],
        },
        {
          content: [],
        },
      ],
    },
    {
      name: 'showdots',
      friendlyName: 'Show dots',
      helperText: 'Show pagination dots',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'autoscroll',
      helperText: 'Automatically rotate to the next slide every few seconds',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'autoScrollSpeed',
      helperText: 'Speed in ms',
      type: 'number',
      defaultValue: 10000,
      showIf: (options: any) => options.get('autoscroll') === true,
    },
    {
      name: 'groupCells',
      helperText: 'If set to true, group cells that fit into the viewport',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'cellAlign',
      type: 'string',
      enum: ['left', 'center', 'right'],
      defaultValue: 'center',
      helperText: 'Align cells within the carousel',
    },
    {
      name: 'contain',
      helperText:
        'Contains cells to carousel element to prevent excess scroll at beginning or end. Has no effect if wrapAround: true.',
      type: 'boolean',
      defaultValue: true,
    },
    {
      name: 'wrapAround',
      helperText:
        'At the end of cells, wrap-around to the other end for infinite scrolling.',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'freeScroll',
      helperText:
        'Enables content to be freely scrolled and flicked without aligning cells to an end position.',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'resize',
      helperText:
        'Adjusts sizes and positions when window is resized. Enabled by default resize: true.',
      type: 'boolean',
      defaultValue: true,
    },
  ],
} as const;

export default CarouselBuilderComponent;
