const FindYourFitUnitBuilderComponent = {
  name: '_FindYourFitUnit_',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
  defaultStyles: {
    marginTop: '0px',
  },
  inputs: [
    {
      name: 'sizes',
      type: 'list',
      copyOnAdd: false,
      onChange: (options: any) => {
        if (options.get('sizes').length > 12) {
          options.set('sizes', options.get('sizes').slice(0, 12));
          alert('Maximum sizes is 12');
        }
      },
      defaultValue: [
        {
          size: 'XS',
          label: 'X SMALL',
          info: [
            {
              title: 'Height',
              description: `4'11" - 5'2"`,
            },
            {
              title: 'Custom title',
              description: '32-34',
            },
          ],
          slides: [
            {
              label: `Name - Height - Size`,
              image:
                'https://cdn.builder.io/api/v1/image/assets%2F68f76aa3250e42aa805f4103ed464c85%2F735b4295a0eb477d84af2a07327ffc16',
            },
          ],
        },
        {
          size: 'S',
          label: 'SMALL',
          info: [
            {
              title: 'Height',
              description: `4'11" - 5'2"`,
            },
          ],
          slides: [
            {
              label: `Name - Height - Size`,
              image:
                'https://cdn.builder.io/api/v1/image/assets%2F68f76aa3250e42aa805f4103ed464c85%2F735b4295a0eb477d84af2a07327ffc16',
            },
          ],
        },
      ],
      subFields: [
        {
          name: 'size',
          type: 'string',
          defaultValue: 'Size',
        },
        {
          name: 'label',
          type: 'string',
          defaultValue: 'Size',
        },
        {
          name: 'info',
          type: 'list',
          subFields: [
            {
              name: 'title',
              type: 'string',
            },
            {
              name: 'description',
              type: 'string',
            },
          ],
        },
        {
          name: 'slideLabelColor',
          helperText: `Color set to all slides labels.`,
          type: 'color',
        },
        {
          name: 'slides',
          type: 'list',
          defaultValue: [
            {
              label: `Name - Height - Size`,
              image:
                'https://cdn.builder.io/api/v1/image/assets%2F68f76aa3250e42aa805f4103ed464c85%2F735b4295a0eb477d84af2a07327ffc16',
            },
          ],
          subFields: [
            {
              name: 'label',
              type: 'string',
              defaultValue: 'Size',
            },
            {
              name: 'labelColor',
              helperText: `Color set to current slide label. Overrides 'Slide Label Color' set on parent 'Size' item.`,
              type: 'color',
            },
            {
              name: 'image',
              type: 'file',
              allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
            },
          ],
        },
      ],
    },
  ],
} as const;

export default FindYourFitUnitBuilderComponent;
