type DataAnalytics = {
  label: string;
  category: string;
};

type Tab = {
  content: Array<any>;
  dataAnalytics: DataAnalytics;
  textLabel: string;
};

const defaultTab: Tab = {
  content: [],
  dataAnalytics: {
    label: '',
    category: 'navigation',
  },
  textLabel: 'Default Label',
};

function createTab(textLabel: string): Tab {
  return { ...defaultTab, textLabel };
}

export const NavTabsListWithContentBuilderComponent = {
  name: '_NavTabsListWithContent_',
  canHaveChildren: true,
  inputs: [
    {
      onChange: (options: any) => {
        if (options.get('tabs') && options.get('tabs').length > 3) {
          options.set('tabs', options.get('tabs').slice(0, 3));
          alert('Maximum tabs is 3.');
        }
      },
      name: 'tabs',
      type: 'list',
      helperText: 'Add tab for each section, ex: Womens, Mens, Scrubs',
      subFields: [
        {
          name: 'content',
          type: 'uiBlocks',
          defaultValue: [],
        },
        {
          name: 'textLabel',
          friendlyName: 'Text Label',
          type: 'string',
          defaultValue: 'Default Text Label',
          localized: true,
          required: true,
        },
        {
          name: 'dataAnalytics',
          friendlyName: 'Data Analytics',
          type: 'object',
          subFields: [
            {
              name: 'label',
              friendlyName: 'Analytics Label',
              helperText:
                'Label provided through to analytics services (ex: about-us)',
              type: 'string',
            },
            {
              name: 'category',
              friendlyName: 'Analytics Category',
              helperText:
                'Category provided through to analytics services (ex: navigation)',
              type: 'string',
            },
          ],
        },
      ],
      defaultValue: [createTab('Shop'), createTab('About Us')],
    },
  ],
} as const;

export default NavTabsListWithContentBuilderComponent;
