const PostregCountdownBuilderComponent = {
  name: '_PostregCountdownTimer_',
  inputs: [
    {
      name: 'timerText',
      type: 'string',
      defaultValue: 'Offer Expires in ',
      helperText: 'The text that precedes the countdown digits.',
    },
  ],
} as const;

export default PostregCountdownBuilderComponent;
