import { MemberDetails } from '~/shared/constants/memberDetails';

import { defaultFontInput } from './fabletics/constants/fontFamilies';

// These member details map to values found in the BuilderMemberDetailText component.
const ACCEPTED_MEMBER_DETAILS = [MemberDetails.MEMBERSHIP_PRICE];

const MemberDetailTextBuilderComponent = {
  name: '_MemberDetailText_',
  image:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgZmlsbD0iIzAwMDAwMCIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0yMDAsNTZWMTg0YTE2LDE2LDAsMCwxLTE2LDE2SDcyYTE2LDE2LDAsMCwxLTE2LTE2VjU2WiIgb3BhY2l0eT0iMC4yIj48L3BhdGg+PHBhdGggZD0iTTIwOCw1NlY4OGE4LDgsMCwwLDEtMTYsMFY2NEgxMzZWMTkyaDI0YTgsOCwwLDAsMSwwLDE2SDk2YTgsOCwwLDAsMSwwLTE2aDI0VjY0SDY0Vjg4YTgsOCwwLDAsMS0xNiwwVjU2YTgsOCwwLDAsMSw4LThIMjAwQTgsOCwwLDAsMSwyMDgsNTZaIj48L3BhdGg+PC9zdmc+',
  inputs: [
    defaultFontInput,
    {
      name: 'memberDetail',
      helperText: 'Choose a member detail to insert',
      enum: ACCEPTED_MEMBER_DETAILS,
    },
  ],
} as const;

export default MemberDetailTextBuilderComponent;
