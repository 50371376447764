const ComparisonUnitItemBuilderComponent = {
  name: '_ComparisonUnitItem_',
  hideFromInsertMenu: true,
  defaultStyles: {
    marginTop: '0px',
  },
  inputs: [
    {
      name: 'title',
      type: 'text',
      defaultValue: 'The Best Product',
      localized: true,
    },
    {
      name: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
    },
    {
      name: 'values',
      type: 'list',
      defaultValue: [{ value: { Default: '' } }, { value: { Default: '' } }],
      copyOnAdd: false,
      subFields: [
        {
          name: 'value',
          friendlyName: 'Value',
          type: 'string',
          localized: true,
        },
      ],
    },
  ],
} as const;

export default ComparisonUnitItemBuilderComponent;
