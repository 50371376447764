import NAV_CONTENT_COLORS from './constants/navContentColors';

export const MyAccountFlyoutMenuBuilderComponent = {
  name: '_MyAccountFlyoutMenu_',
  canHaveChildren: true,
  withChildren: true,
  childRequirements: {
    message:
      'Child component for a MyAccountFlyoutMenu must be a ListColumn or MemberInfo',
    query: {
      'component.name': {
        $in: ['_ListColumn_', '_MemberInfo_'],
      },
    },
  },
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: '_ListColumn_',
        options: {
          hasColumnHeader: false,
          columnHeader: '',
          columnHeaderColor: NAV_CONTENT_COLORS.DEFAULT,
        },
      },
      children: [
        {
          '@type': '@builder.io/sdk:Element',
          component: {
            name: '_AccountNavLink_',
            options: {
              linkText: 'Profile',
              linkUrl: '/account/profile',
            },
          },
        },
      ],
    },
  ],
  inputs: [
    {
      name: 'isOn',
      friendlyName: 'Is On',
      helperText:
        'Turning toggle on expands component for easier management & previewing abilities',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'dataAnalytics',
      friendlyName: 'Data Analytics',
      type: 'object',
      subFields: [
        {
          name: 'label',
          friendlyName: 'Analytics Label',
          helperText:
            'Label provided through to analytics services (ex: about-us)',
          type: 'string',
        },
        {
          name: 'category',
          friendlyName: 'Analytics Category',
          helperText:
            'Category provided through to analytics services (ex: navigation)',
          type: 'string',
        },
      ],
    },
  ],
} as const;

export default MyAccountFlyoutMenuBuilderComponent;
