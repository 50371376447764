import { defaultFontInput } from './constants/fontFamilies';

const FableticsHeadingBuilderComponent = {
  name: '_Heading_',
  image:
    'https://firebasestorage.googleapis.com/v0/b/builder-3b0a2.appspot.com/o/images%2Fbaseline-text_fields-24px%20(1).svg?alt=media&token=12177b73-0ee3-42ca-98c6-0dd003de1929',
  defaultStyles: {
    marginTop: '0px',
  },
  inputs: [
    defaultFontInput,
    {
      name: 'textAlign',
      enum: ['left', 'center', 'right'],
      defaultValue: 'center',
    },
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Example Title',
      required: true,
      localized: true,
    },
    {
      name: 'textColor',
      type: 'color',
      defaultValue: '#333333',
    },
    {
      name: 'subtitle',
      type: 'richText',
      localized: true,
      defaultValue: 'This is the description of the heading, have fun!',
    },
    {
      name: 'backgroundColor',
      type: 'color',
      defaultValue: '#fff',
    },
  ],
} as const;

export default FableticsHeadingBuilderComponent;
