const VideoCarouselBuilderComponent = {
  name: '_VideoCarousel_',
  inputs: [
    {
      type: 'list',
      name: 'videos',
      subFields: [
        {
          type: 'reference',
          helperText: 'Select a video',
          name: 'video',
          model: 'carousel-video',
        },
      ],
      defaultValue: [],
    },
    {
      name: 'title',
      type: 'text',
      defaultValue: 'Video carousel title',
    },
    {
      type: 'boolean',
      friendlyName: 'Is logged-in version',
      name: 'isLoggedInVersion',
      defaultValue: false,
    },
    {
      name: 'legalDisclaimer',
      type: 'text',
      defaultValue: '*Fabletics Ambassadors are compensated partners',
    },
  ],
} as const;

export default VideoCarouselBuilderComponent;
