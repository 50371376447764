import { defaultFontInput } from './constants/fontFamilies';

const FableticsTextBuilderComponent = {
  name: '_Text_',
  image:
    'https://firebasestorage.googleapis.com/v0/b/builder-3b0a2.appspot.com/o/images%2Fbaseline-text_fields-24px%20(1).svg?alt=media&token=12177b73-0ee3-42ca-98c6-0dd003de1929',
  noWrap: true,
  inputs: [
    defaultFontInput,
    {
      name: 'text',
      type: 'longText',
      defaultValue: 'Fabletics',
      localized: true,
    },
  ],
} as const;

export default FableticsTextBuilderComponent;
