export const FableticsLoyaltyVideoConfig = {
  name: '_LoyaltyVideo_',
  inputs: [
    {
      name: 'video',
      type: 'text',
      helperText: 'Video URL or Vimeo ID',
      required: true,
    },
    {
      name: 'rule',
      type: 'reference',
      helperText: 'Select a ruleset',
      model: 'points-for-actions',
      required: true,
    },
    {
      name: 'sectionHeader',
      type: 'object',
      defaultValue: {
        sectionTitle: '',
        sectionSubtitle: '',
      },
      subFields: [
        {
          name: 'sectionTitle',
          type: 'text',
          helperText: 'Text to show as a title on top of the content',
        },
        {
          name: 'sectionSubtitle',
          type: 'text',
          helperText: 'Text to show under the title',
        },
      ],
    },
    {
      name: 'title',
      type: 'text',
      helperText: 'Text to show as a title on the left side of the banner',
      required: true,
    },
    {
      name: 'subtitle',
      type: 'text',
      helperText: 'Text to show under the title',
    },
    {
      name: 'subtitleConfig',
      type: 'object',
      showIf: (options: any) => options.get('subtitle'),
      defaultValue: {
        showPoints: true,
      },
      subFields: [
        {
          name: 'showPoints',
          type: 'boolean',
          helperText:
            'Show the points earned for the video highlighted next to the description',
        },
      ],
    },
    {
      name: 'showFor',
      type: 'number',
      helperText:
        'Number of days to show the component for. Leave blank to show indefinitely. (Optional)',
    },
    {
      name: 'startDate',
      type: 'date',
      helperText: 'Show the component starting on this date',
    },
    {
      type: 'list',
      name: 'actions',
      helperText:
        'An action in the list is to be a video banner. The last action is to have a border in the UI.',
      subFields: [
        {
          type: 'reference',
          helperText: 'Select a action',
          name: 'action',
          model: 'points-for-actions',
        },
        {
          name: 'displayPoints',
          type: 'number',
          required: true,
          helperText: 'The number of points is to be shown to users',
        },
      ],
      defaultValue: [],
    },
    {
      name: 'progressTrackerPoints',
      type: 'string',
      defaultValue: '1000 pts',
    },
    {
      name: 'progressTrackerOffLabel',
      type: 'string',
      friendlyName: "Progress tracker 'off' label",
      defaultValue: '$10 off',
      helperText: "The maximum length of the 'off' label is 7 characters",
      onChange: (options: any) => {
        if (options.get('progressTrackerOffLabel').length > 7) {
          options.set(
            'progressTrackerOffLabel',
            options.get('progressTrackerOffLabel').slice(0, 7)
          );
          alert(
            "the maximum length of the 'off' label is 7, shorten it to continue"
          );
        }
      },
    },
  ],
} as const;

export default FableticsLoyaltyVideoConfig;
