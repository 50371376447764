export const ImageComparisonSliderBuilderComponent = {
  withChildren: true,
  name: '_ImageComparisonSlider_',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
  defaultStyles: {
    marginTop: '0px',
  },
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Text',
        options: {
          text: 'Example Title',
        },
      },
      responsiveStyles: {
        large: {
          fontSize: '22px',
          fontWeight: '700',
          marginBottom: '10px',
          marginTop: '10px',
        },
      },
    },
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Text',
        options: {
          text: 'This is the description of an Image Comparison Slider.',
          richText: true,
        },
      },
      responsiveStyles: {
        large: {
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '160%',
        },
      },
    },
  ],
  childRequirements: {
    message: 'Only text allowed',
    query: {
      'component.name': { $in: ['Text'] },
    },
  },
  inputs: [
    {
      name: 'leftImage',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg', 'webp', 'avif'],
      defaultValue:
        'https://cdn.builder.io/api/v1/image/assets%2F68f76aa3250e42aa805f4103ed464c85%2Fa96c1213fc0a4ee482acf359299eddc0',
    },
    {
      name: 'rightImage',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg', 'webp', 'avif'],
      defaultValue:
        'https://cdn.builder.io/api/v1/image/assets%2F68f76aa3250e42aa805f4103ed464c85%2Fa96c1213fc0a4ee482acf359299eddc0',
    },
    {
      name: 'sliderPosition',
      enum: ['left', 'right'],
      defaultValue: 'left',
    },
    {
      name: 'backgroundColor',
      type: 'color',
      defaultValue: '#ffffff',
    },
  ],
} as const;

export default ImageComparisonSliderBuilderComponent;
