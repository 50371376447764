const LoyaltyPointsForActionsBuilderComponent = {
  name: '_LoyaltyPointsForActions_',
  inputs: [
    {
      name: 'desktopHeader',
      type: 'object',
      defaultValue: {
        title: '',
        collapsedSubtitle: '',
        expandedSubtitle: '',
      },
      subFields: [
        {
          name: 'title',
          type: 'text',
          helperText:
            'Text to show as a title on top of the content (Desktop).',
        },
        {
          name: 'collapsedSubtitle',
          type: 'richText',
          helperText:
            'Text to show under the title when the module is collapsed (Desktop).',
        },
        {
          name: 'expandedSubtitle',
          type: 'richText',
          helperText:
            'Text to show under the title when the module is expanded (Desktop).',
        },
      ],
    },
    {
      name: 'mobileHeader',
      type: 'object',
      defaultValue: {
        title: '',
        subtitle: '',
      },
      subFields: [
        {
          name: 'title',
          type: 'text',
          helperText:
            'Text to show as a title on top of the content (Mobile Web & App).',
        },
        {
          name: 'subtitle',
          type: 'text',
          helperText: 'Text to show under the title (Mobile Web & App).',
        },
      ],
    },
    {
      name: 'showFor',
      type: 'number',
      helperText:
        'Number of days to show the component for. Leave blank to show indefinitely. (Optional)',
    },
    {
      name: 'startDate',
      type: 'date',
      helperText: 'Show the component starting on this date',
    },
    {
      type: 'list',
      name: 'actions',
      helperText: 'The list of actions to render in the component.',
      subFields: [
        {
          type: 'reference',
          helperText: 'Select a action',
          name: 'action',
          model: 'points-for-actions',
        },
        {
          name: 'displayPoints',
          type: 'number',
          required: true,
          helperText: 'The number of points is to be shown to users',
        },
      ],
      defaultValue: [],
    },
  ],
} as const;

export default LoyaltyPointsForActionsBuilderComponent;
