const AccordionUnitBuilderComponent = {
  name: '_AccordionUnit_',
  defaultStyles: {
    marginTop: '0px',
  },
  inputs: [
    {
      name: 'items',
      type: 'list',
      copyOnAdd: false,
      onChange: (options: any) => {
        if (options.get('items').length > 6) {
          options.set('items', options.get('items').slice(0, 6));
          alert('Maximum items is 6');
        }
      },
      defaultValue: [
        {
          itemTitle: 'Product Information',
          title: 'Title goes here',
          description:
            'This is the Product Information section, try it and fill it with some content...',
        },
        {
          itemTitle: 'Size & Fit',
          title: 'Title goes here',
          description: 'This is the Size & Fit section ... try it out!',
        },
      ],
      subFields: [
        {
          name: 'itemTitle',
          type: 'string',
          defaultValue: 'Item Title',
          localized: true,
        },
        {
          name: 'title',
          type: 'string',
          defaultValue: 'Title goes here',
          localized: true,
        },
        {
          name: 'description',
          type: 'richText',
          defaultValue:
            'This is the description, try it and fill it with some content...',
          localized: true,
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
        },
        {
          name: 'backgroundColor',
          type: 'color',
          defaultValue: '#ffffff',
        },
      ],
    },
  ],
} as const;

export default AccordionUnitBuilderComponent;
