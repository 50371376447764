import GRID_FILTERS from './constants/gridFilters';

const FableticsCollectionBuilderComponent = {
  name: '_Collection_',
  inputs: [
    {
      name: 'pdpLinkOverride',
      friendlyName: 'PDP Link Override',
      type: 'string',
      helperText:
        'Instead of going to PDP when user clicks on a product card, they will be directed to this URL.',
      advanced: true,
    },
    {
      name: 'fplGlobalCodes',
      type: 'list',
      localized: false,
      subFields: [
        {
          name: 'fplGlobalCode',
          type: 'string',
          localized: false,
          required: true,
        },
      ],
    },
    {
      name: 'productCategoryIds',
      helperText: 'You can add more than one.',
      type: 'list',
      localized: false,
      subFields: [
        {
          name: 'productCategoryId',
          type: 'number',
          localized: false,
          required: true,
        },
      ],
    },
    {
      name: 'excludeFpls',
      type: 'list',
      localized: false,
      subFields: [
        {
          name: 'excludeFpl',
          type: 'number',
          localized: false,
          required: false,
        },
      ],
    },
    {
      name: 'excludeCategoryIds',
      friendlyName: 'Exclude Cat IDs',
      helperText:
        'Product Category Ids you would like excluded from the result set.',
      type: 'list',
      localized: false,
      subFields: [
        {
          name: 'excludeCategoryId',
          friendlyName: 'Exclude Cat ID',
          type: 'number',
          localized: false,
          required: false,
        },
      ],
    },
    {
      name: 'excludeTagIds',
      type: 'list',
      localized: false,
      subFields: [
        {
          name: 'excludeTagId',
          type: 'number',
          localized: false,
          required: false,
        },
      ],
    },
    {
      name: 'tagIds',
      helperText: 'You can add more than one.',
      type: 'list',
      localized: false,
      subFields: [
        {
          name: 'tagId',
          type: 'number',
          localized: false,
          required: true,
        },
      ],
    },
    {
      name: 'productTypeIds',
      type: 'list',
      localized: false,
      subFields: [
        {
          name: 'productTypeId',
          type: 'number',
          localized: false,
          required: true,
        },
      ],
    },
    {
      name: 'filters',
      helperText:
        'You can add more than one. Filters will show in the order you set here.',
      type: 'list',
      localized: false,
      defaultValue: null,
      subFields: [
        {
          name: 'filter',
          type: 'string',
          localized: false,
          required: true,
          enum: GRID_FILTERS,
        },
      ],
    },
    {
      name: 'sortList',
      type: 'list',
      localized: false,
      friendlyName: 'Sort Dropdown Options',
      helperText:
        'The first sort option in your list will be set as the default sort in the sort dropdown.  Drag the sort options to prioritize',
      defaultValue: [
        {
          sortOption: 'newest',
        },
        {
          sortOption: 'bestsellers',
        },
        {
          sortOption: 'topReviewed',
        },
        {
          sortOption: 'priceLowToHigh',
        },
        {
          sortOption: 'priceHighToLow',
        },
      ],
      subFields: [
        {
          name: 'filterLabel',
          friendlyName: 'Sort Label',
          type: 'string',
          defaultValue: '',
          helperText: 'Applies to both Sort Options and Custom Sort Lists',
        },
        {
          name: 'sortOption',
          friendlyName: 'Sort Option',
          type: 'string',
          localized: false,
          required: false,
          defaultValue: '',
          enum: [
            '',
            'bestsellers',
            'fplSort',
            'newest',
            'priceHighToLow',
            'priceLowToHigh',
            'topReviewed',
          ],
        },
        {
          name: 'customSort',
          friendlyName: 'Custom Sort',
          type: 'object',
          localized: false,
          helperText:
            'Please do not use this field unless you have tested this sort list and it is a proven winner',
          defaultValue: {
            direction: 'asc',
          },
          subFields: [
            {
              name: 'field',
              friendlyName: 'Custom Sort List',
              helperText:
                'The Custom Sort List will override any selection made in the Sort Option dropdown input',
              type: 'string',
              localized: false,
            },
          ],
        },
      ],
    },
    {
      name: 'sort',
      friendlyName: 'DO NOT USE',
      type: 'object',
      hideFromUI: true,
      defaultValue: {
        direction: 'asc',
      },
      subFields: [
        {
          name: 'hideSortOption',
          friendlyName: 'DO NOT USE',
          type: 'boolean',
          default: false,
        },
        {
          name: 'field',
          friendlyName: 'DO NOT USE',
          type: 'string',
        },
        {
          name: 'direction',
          friendlyName: 'DO NOT USE',
          type: 'string',
          enum: ['asc', 'desc'],
          hideFromUI: true,
        },
        {
          name: 'filterLabel',
          friendlyName: 'DO NOT USE',
          type: 'string',
        },
      ],
    },
    {
      name: 'includeOutOfStock',
      helperText:
        'Turning this on will surface out of stock products on the grid.',
      type: 'boolean',
      localized: false,
    },
    {
      name: 'excludePreorderProducts',
      friendlyName: 'Exclude Preorder Products (Web Only)',
      helperText:
        'Turning this on will remove products that are configured for preorder from the grid.',
      type: 'boolean',
      localized: false,
      defaultValue: false,
    },
    {
      name: 'brand',
      helperText:
        "Determines which brand's products should be surfaced on the grid.",
      type: 'string',
      localized: false,
      enum: ['both', 'fabletics', 'yitty'],
      defaultValue: 'fabletics',
    },
    {
      name: 'label',
      type: 'string',
      friendlyName: 'Grid Header',
      helperText:
        'This field displays a grid text header above the first product',
      localized: true,
    },
    {
      name: 'gridAssetContainer',
      friendlyName: 'In-Grid Asset Container',
      helperText:
        "This container powers the assets inside of the grid on desktop- use the custom vars in CMS to set position and size.  Please note that Mobile App only supports the 'position' custom var.",
      type: 'string',
    },
    {
      name: 'gridAssetContainerMobile',
      friendlyName: 'In-Grid Asset Container - Mobile',
      helperText:
        "This container powers the assets inside of the grid on mobile- use the custom vars in CMS to set position and size.  Please note that Mobile App only supports the 'position' custom var.",
      type: 'string',
      localized: false,
    },
    {
      name: 'productSource',
      friendlyName: 'P-Source',
      helperText:
        'This field sets the P-Source name to track revenue for the page',
      type: 'string',
    },
    {
      name: 'maxItemCount',
      helperText:
        'Maximum number of products you want displayed for this collection of products.',
      type: 'number',
    },
    {
      name: 'gridGender',
      friendlyName: 'Grid Gender',
      type: 'string',
      localized: false,
      helperText:
        'Defines the gender of the grid in this Collection node. Grid Gender value must be set to Female or Male in every Collection node on this page before enabling Automatic-Size filtering. Currently WEB ONLY, not yet supported on Mobile App',
      enum: ['female', 'male', 'not-set'],
      defaultValue: 'not-set',
    },
    {
      name: 'isCarouselV2',
      friendlyName: 'Is Carousel',
      helperText:
        'Do not use the is carousel feature if you are also using in-grid assets.',
      type: 'object',
      localized: false,
      advanced: true,
      subFields: [
        {
          name: 'desktop',
          type: 'boolean',
          localized: false,
        },
        {
          name: 'mobile',
          type: 'boolean',
          localized: false,
        },
      ],
    },
    {
      name: 'showLastChance',
      friendlyName: 'Show Last Chance',
      helperText:
        'Toggle this setting On to surface Last Chance products on this grid when no size filters are applied. This should be Off by default on non-Last Chance grids so that Last Chance products only show on regular grids when a size filter is applied.',
      type: 'boolean',
      localized: false,
      advanced: true,
    },
    {
      name: 'sideNav',
      friendlyName: 'Side Nav Settings (Web Only)',
      type: 'object',
      localized: false,
      advanced: true,
      subFields: [
        {
          name: 'shouldShowCategoryDropdown',
          helperText:
            'Surfaces the category navigation on both desktop + mobile',
          type: 'boolean',
          localized: false,
          defaultValue: false,
        },
        {
          name: 'shouldShowCategoryHeading',
          type: 'boolean',
          localized: false,
          defaultValue: false,
        },
        {
          name: 'categoryNavContainer',
          friendlyName: 'Category Navigationizer ID (Web Only)',
          helperText:
            'This is the nav config name inside Nav Tool- the category nav will not show without this field.',
          type: 'string',
        },
      ],
    },

    {
      name: 'gridSortFeature',
      helperText: 'Feature name for grid sort AB testing.',
      type: 'string',
      localized: false,
      advanced: true,
    },
    {
      name: 'algoOptions',
      type: 'object',
      localized: false,
      advanced: true,
      subFields: [
        {
          name: 'esIndex',
          friendlyName: 'ES Index',
          type: 'string',
          enum: [
            '',
            'boutique',
            'boutique_flm',
            'boutique_ytw',
            'ad_id',
            'quiz_question_algo_1',
            'quiz_question_algo_2',
            'boutique_scwa',
            'boutique_scwb',
            'boutique_scma',
            'boutique_scmb',
          ],
        },
        {
          name: 'algorithmPageSize',
          type: 'number',
          defaultValue: null,
          localized: false,
          required: false,
        },
        {
          name: 'fallbackFpl',
          friendlyName: 'Fallback FPL',
          helperText:
            'Sets the fallback FPL when the algo threshold is not met. Supports either FPL code or ID',
          type: 'string',
          required: false,
        },
        {
          name: 'fallbackFplThreshold',
          friendlyName: 'FPL fallback threshold',
          type: 'number',
          defaultValue: 0,
          localized: false,
          required: false,
        },
        {
          name: 'disableCollectionOnThresholdUnmet',
          friendlyName: 'Disable Collection When Threshold Unmet?',
          helperText:
            'Disables the collection entirely when the algo threshold is not met and no Fallback FPL is specified.',
          type: 'boolean',
          defaultValue: false,
          required: false,
        },
        {
          name: 'debug',
          friendlyName: 'Algorithm Debug (FOR DEVELOPERS ONLY)',
          type: 'boolean',
          defaultValue: false,
          hideFromUI: true,
        },
      ],
    },
    {
      name: 'imageType',
      helperText:
        'Determines if plus size or non plus size images show on the grid- overrides your saved and/or selected size.',
      type: 'string',
      localized: false,
      advanced: true,
      enum: ['default', 'plus'],
      defaultValue: 'default',
    },
    {
      name: 'gridStyleGuide',
      friendlyName: 'Discovery Guide',
      type: 'object',
      localized: false,
      advanced: true,
      subFields: [
        {
          name: 'tagList',
          friendlyName: 'Filter List',
          type: 'string',
          localized: false,
          enum: GRID_FILTERS,
          required: true,
        },
        {
          name: 'assetContainers',
          type: 'object',
          localized: false,
          required: true,
          subFields: [
            {
              name: 'desktop',
              type: 'string',
              localized: false,
              required: true,
            },
            {
              name: 'mobile',
              type: 'string',
              localized: false,
              required: true,
            },
            {
              name: 'marketingCard',
              type: 'string',
              localized: false,
              required: true,
            },
          ],
        },
      ],
    },
    {
      name: 'gridCTA',
      type: 'object',
      helperText:
        'This section controls a grid CTA that displays for both the grid view AND the carousel view.',
      localized: false,
      advanced: true,
      defaultValue: {
        fontVariant: 'condensedVipHomepageFamily',
      },
      subFields: [
        {
          name: 'desktop',
          type: 'boolean',
          localized: false,
        },
        {
          name: 'mobile',
          type: 'boolean',
          localized: false,
        },
        {
          name: 'fontVariant',
          type: 'string',
          localized: false,
          enum: [],
          hideFromUI: true,
        },
        {
          name: 'label',
          type: 'string',
          helperText:
            'This is the text on the CTA button, by default it is "View All".',
          localized: true,
          defaultValue: 'View All',
        },
        {
          name: 'url',
          type: 'string',
          localized: true,
        },
      ],
    },
    {
      name: 'developerSettings',
      type: 'object',
      localized: false,
      advanced: true,
      subFields: [
        {
          friendlyName: 'Use Northstar',
          name: 'isGqlProducts',
          type: 'boolean',
          localized: false,
          defaultValue: false,
        },
        {
          friendlyName: 'Should Server Side Render',
          name: 'serverSideRender',
          type: 'boolean',
          localized: false,
          defaultValue: false,
        },
        {
          friendlyName: 'Initial Products Cache TTL(milliseconds)',
          name: 'initialProductsCachettl',
          type: 'number',
          localized: false,
          defaultValue: 0,
        },
      ],
    },
  ],
} as const;

export default FableticsCollectionBuilderComponent;
