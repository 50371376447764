const SignInWithModalBuilderComponent = {
  name: '_SignInWithModal_',
  inputs: [
    {
      name: 'precedingText',
      friendlyName: 'Preceding Text',
      helperText: 'Text will appear near Sign In link for logged-out users',
      type: 'string',
      defaultValue: 'Already a member?',
      localized: true,
    },
    {
      name: 'actionText',
      friendlyName: 'Action Text',
      helperText: 'Text actioning the user to sign in',
      type: 'string',
      defaultValue: 'Sign In',
      localized: true,
      required: true,
    },
    {
      name: 'dataAnalytics',
      friendlyName: 'Data Analytics',
      type: 'object',
      subFields: [
        {
          name: 'label',
          friendlyName: 'Analytics Label',
          helperText:
            'Label provided through to analytics services. Example: T2_Men_Bottoms',
          type: 'string',
          required: true,
          localized: false,
        },
        {
          name: 'category',
          friendlyName: 'Analytics Category',
          helperText:
            'Category provided through to analytics services. Example: navigation',
          type: 'string',
          required: true,
          localized: false,
        },
      ],
    },
  ],
} as const;

export default SignInWithModalBuilderComponent;
