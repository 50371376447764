const DividerBuilderComponent = {
  name: '_Divider_',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2Fef36d2a846134910b64b88e6d18c5ca5',
  defaultStyles: {
    height: '60px',
    marginTop: '0px',
  },
  inputs: [
    {
      name: 'color',
      type: 'color',
      defaultValue: '#DDDDDD',
    },
    {
      name: 'backgroundColor',
      type: 'color',
      defaultValue: '#fff',
    },
  ],
} as const;

export default DividerBuilderComponent;
