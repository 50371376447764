const SkipTheMonthCTA = {
  name: '_SkipTheMonthCTA_',
  noWrap: true,
  inputs: [
    {
      name: 'overrideDisabled',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'text',
      type: 'string',
      defaultValue: 'Skip this month',
      localized: true,
      helperText: 'CTA Text',
    },
    {
      name: 'link',
      type: 'string',
      defaultValue: '/skip/confirmation',
      localized: true,
      helperText:
        '/skip/confirmation is the default reDirect (skip logic in code)',
    },
  ],
} as const;

export default SkipTheMonthCTA;
