const breakpointList = ['mobile', 'tablet', 'tablet+', 'desktop', 'desktop+'];

const cardVariants = ['default', 'VIPHomepage', 'fableticsCares', 'giftGuide'];

const FableticsMarketingCardsBuilderComponent = {
  name: '_MarketingCards_',
  inputs: [
    {
      name: 'cardVariant',
      type: 'string',
      enum: cardVariants,
      defaultValue: 'VIPHomepage',
      localized: false,
    },
    {
      name: 'container',
      type: 'string',
      defaultValue: '',
      required: true,
      localized: false,
    },
    {
      name: 'mobileContainer',
      type: 'string',
      defaultValue: '',
      required: true,
      localized: false,
    },
    {
      name: 'mobileCardLayout',
      type: 'string',
      enum: ['vertical', 'horizontal'],
      defaultValue: 'vertical',
      localized: false,
    },
    {
      name: 'hideNavigationMobile',
      type: 'boolean',
      localized: false,
    },
    {
      name: 'isCarousel',
      type: 'object',
      localized: false,
      defaultValue: { desktop: false, mobile: false },
      subFields: [
        { name: 'desktop', type: 'boolean' },
        { name: 'mobile', type: 'boolean' },
      ],
    },
    {
      name: 'gridColumnsSettings',
      type: 'object',
      localized: false,
      defaultValue: {
        desktop: 4,
        tablet: 2,
        mobile: 1,
      },
      subFields: breakpointList
        .filter((s) => !s.includes('+'))
        .map((breakpoint) => ({
          name: breakpoint,
          type: 'number',
          localized: false,
        })),
    },
  ],
};

export default FableticsMarketingCardsBuilderComponent;
